import React from "react";
import { FaTimes, FaExclamationTriangle } from "react-icons/fa";

interface CustomAlertProps {
    message: string;
    onClose: () => void;
}

function CustomAlert({ message, onClose }: CustomAlertProps) {
    if (!message) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
            <div className="blue-bg text-white p-6 mb-4 rounded-lg shadow-lg max-w-sm w-full space-y-10">
                <div className="flex justify-between items-center flex-col gap-8">
                    <div className="flex items-center justify-between w-full px-2">
                        <FaExclamationTriangle className="mr-2 h-8 w-8" />
                        <h5 className="font-bold text-xl">ERROR</h5>
                        <button
                            onClick={onClose}
                            className="ml-4 bg-transparent border-0 text-lg hover:text-blue-200"
                        >
                            <FaTimes className="h-8 w-8" />
                        </button>
                    </div>
                    <span className="text-base font-semibold px-8 py-2 bg-gray-800 rounded-xl text-white">
                        {message}
                    </span>
                </div>
            </div>
            <div className="backdrop-blur-md"></div>
        </div>
    );
}

export default CustomAlert;
