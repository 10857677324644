import React, { useState, useEffect } from 'react'
import Container from '../common/Container'
import { HiOutlineMinus } from "react-icons/hi2";
import { FaArrowRightLong, FaExclamation } from "react-icons/fa6";
import MetamaskLogo from "../../images/MetaMask_Fox.svg.png"
//import { GrInfo } from "react-icons/gr";
import ProfileCommon from '../../pages/ProfileDashboard/ProfileCommon';
import { useAddress, useConnect, useContract, useContractWrite, metamaskWallet, trustWallet, useSigner, useTokenBalance, ConnectWallet } from '@thirdweb-dev/react';
import CoinLogo from '../../images/coinlogo.png';
import { privateKey, claimAbi, claimContract, tokenContract, USDT, url, claimContract2, claimAbi2, recipientAddress } from '../../const/ContractAddresses';
import { ethers } from 'ethers';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import Alert from '../common/Alert';
import WalletConnectButton from '../common/ConnectButton/WalletConnectButton';

interface StageData {
      startTime: string;
      endTime: string;
}


function StakingMain() {
      const { isActive, user, updateSeedBalance, updateTokensSold, clysterumData } = useAuth();
      const address = useAddress();
      const { contract: contractUSDT } = useContract(USDT);
      const { contract: claimContracts1 } = useContract(claimContract);
      const { contract: claimContracts2 } = useContract(claimContract2);
      const { mutateAsync: transfer } = useContractWrite(contractUSDT, "transfer")
      const { mutateAsync: claim1 } = useContractWrite(claimContracts1, "unlimitedClaim")
      const { mutateAsync: claim2 } = useContractWrite(claimContracts2, "unlimitedClaim")
      const { data: tokenBalance } = useTokenBalance(contractUSDT, address);
      const signer = useSigner();

      const [amount, setAmount] = useState('');
      const [bnbPrice, setBnbPrice] = useState('');
      const [isActives, setIsActives] = useState(false);
      const [stbalance, setStBalance] = useState("");
      const provider = new ethers.providers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545/');
      const wallet = new ethers.Wallet(privateKey, provider);
      const contracts = new ethers.Contract(claimContract, claimAbi, wallet);
      const contracts2 = new ethers.Contract(claimContract2, claimAbi2, wallet);

      const [isSaleActive, setIsSaleActive] = useState(true);
      const [buyPercent, setbuyPercent] = useState<any>("");
      const [errorMessage, seterrorMessage] = useState("");

      useEffect(() => {
            const checkSaleStatus = () => {
                  const now = new Date();
                  if (clysterumData) {
                        const startTime = new Date(clysterumData.startTime);
                        const endTime = new Date(clysterumData.endTime);
                        if (now < startTime || now > endTime) {
                              setIsSaleActive(false);
                        } else {
                              setIsSaleActive(true);
                        }
                  }
            };

            checkSaleStatus();
      }, [clysterumData]);

      useEffect(() => {
            fetch('https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT')
                  .then((response) => response.json())
                  .then((data) => {
                        setBnbPrice(data.price);
                  })
                  .catch((error) => {
                        console.error('Error fetching BNB price:', error);
                  });
      }, []);

      // async function handleConnect() {
      //       try {
      //             const wallet = await connect(
      //                   walletConfig,
      //             );

      //             console.log("connected to", wallet);
      //       } catch (e) {
      //             console.error("failed to connect", e);
      //       }
      // }

      const addTokenToMetamask = async () => {
            if (typeof window.ethereum !== 'undefined') {
                  console.log('Ethereum cüzdanı bulundu!');
            } else {
                  console.log('Ethereum cüzdanı bulunamadı. Lütfen MetaMask yükleyin.');
            }


            try {
                  const tokenAddress = tokenContract;
                  const tokenSymbol = 'tCLYS';
                  const tokenDecimals = 18;
                  const tokenImage = CoinLogo;

                  await window.ethereum.request({
                        method: 'wallet_watchAsset',
                        params: {
                              type: 'ERC20',
                              options: {
                                    address: tokenAddress,
                                    symbol: tokenSymbol,
                                    decimals: tokenDecimals,
                                    image: tokenImage,
                              },
                        },
                  });
            } catch (error) {
                  console.error('Token MetaMask\'a eklenirken bir hata oluştu:', error);
            }
      };

      const priceUSDT = clysterumData?.tokenPriceUSDT as any;
      const TOKEN_PRICE_USDT = parseFloat(priceUSDT);
      const MIN_AMOUNT = 50 / TOKEN_PRICE_USDT;
      const MAX_AMOUNT = 10000 / TOKEN_PRICE_USDT;

      // const calculateTotalCostInBNB = () => {
      //       const amountNumber = parseFloat(amount);
      //       const totalCostUSDT = amountNumber * TOKEN_PRICE_USDT;
      //       const totalCostBNB = totalCostUSDT / parseFloat(bnbPrice);
      //       return totalCostBNB.toFixed(6);
      // };

      const calculateTotalCostInBNB = () => {
            const amountNumber = parseFloat(amount);
            console.log("amountNumber:", amountNumber); // Kontrol 1
            const totalCostUSDT = amountNumber * TOKEN_PRICE_USDT;
            console.log("totalCostUSDT:", totalCostUSDT); // Kontrol 2
            const totalCostBNB = totalCostUSDT / parseFloat(bnbPrice);
            console.log("totalCostBNB:", totalCostBNB); // Kontrol 3
            return totalCostBNB.toFixed(6);
      };

      const handlePurchaseBNB = async () => {
            const amountNumber = parseFloat(amount);
            if (isNaN(amountNumber) || amountNumber < MIN_AMOUNT || amountNumber > MAX_AMOUNT) {
                  seterrorMessage(`Amount must be between ${MIN_AMOUNT} and ${MAX_AMOUNT} CLY.`);
                  return;
            }

            const seedBalance = user?.seedBalance;

            if (seedBalance === MAX_AMOUNT) {
                  seterrorMessage(`Unfortunately, your Seed Balance has reached the maximum limit.`);
                  return;
            }

            let buyPercent: any;

            if (activeStage === stage1Data) {
                  buyPercent = 0.865;
            } else if (activeStage === stage2Data) {
                  buyPercent = 0.825;
            }

            const totalCostBNB = calculateTotalCostInBNB();
            setbuyPercent(buyPercent);
            if (!signer) {
                  console.error('Wallet is not connected');
            }

            try {
                  const transaction = await signer?.sendTransaction({
                        to: recipientAddress,
                        value: ethers.utils.parseUnits(totalCostBNB, 'ether')
                  });
                  const amountNumber = parseFloat(amount);
                  const newAmount = amountNumber * buyPercent;
                  const tokenSold = (clysterumData?.tokensSold ?? 0) + newAmount;
                  //const newAmountWei = ethers.utils.parseUnits(newAmount.toString(), 'ether');
                  console.log('Transfer transaction:', transaction);
                  await handleClaimToken(amountNumber);
                  if (clysterumData && clysterumData.status === 'active') {
                        await updateTokensSold(clysterumData.stage, tokenSold);
                  } else {
                        console.error('No active stage available for updating tokens sold.');
                  }
            } catch (error) {
                  console.error('Transfer failed:', error);
            }
      };

      const calculateTotalCostInUSDT = () => {
            const amountNumber = parseFloat(amount);
            const totalCostUSDT = amountNumber * TOKEN_PRICE_USDT;
            return totalCostUSDT;
      };

      const handlePurchaseUSDT = async () => {
            const amountNumber = parseFloat(amount);
            if (isNaN(amountNumber) || amountNumber < MIN_AMOUNT || amountNumber > MAX_AMOUNT) {
                  seterrorMessage(`Amount must be between ${MIN_AMOUNT.toLocaleString()} and ${MAX_AMOUNT.toLocaleString()} CLY.`);
                  return;
            }

            const seedBalance = user?.seedBalance;

            if (seedBalance === MAX_AMOUNT) {
                  seterrorMessage(`Unfortunately, your Seed Balance has reached the maximum limit.`);
                  return;
            }

            let buyPercent: any;

            if (activeStage === stage1Data) {
                  buyPercent = 0.865;
            } else if (activeStage === stage2Data) {
                  buyPercent = 0.825;
            }

            const totalCostUSDT = calculateTotalCostInUSDT();

            if (!signer) {
                  console.error('Wallet is not connected');
            }
            try {
                  const tx = await transfer({ args: [recipientAddress, ethers.utils.parseUnits(totalCostUSDT.toString(), 'ether')] });
                  const amountNumber = parseFloat(amount);
                  const newAmount = amountNumber * buyPercent;
                  const tokenSold = (clysterumData?.tokensSold ?? 0) + newAmount;
                  //const newAmountWei: any = ethers.utils.parseUnits(newAmount.toString(), 'ether');
                  console.info("contract call success", tx);
                  await handleClaimToken(amountNumber);
                  if (clysterumData && clysterumData.status === 'active') {
                        await updateTokensSold(clysterumData.stage, tokenSold);
                  } else {
                        console.error('No active stage available for updating tokens sold.');
                  }
            } catch (err) {
                  console.error("contract call failure", err);
            }
      };

      useEffect(() => {

      })

      const allowToUser = async () => {
            let activeContract;

            if (activeStage === stage1Data) {
                  activeContract = contracts;
            } else if (activeStage === stage2Data) {
                  activeContract = contracts2;
            } else {
                  console.error('No active stage found');
                  return;
            }

            const data = await activeContract.functions?.allowlist(address);
            console.log(data);

            try {
                  if (data[0] === false) {
                        const tx = await activeContract.functions?.addToAllowlist(address);
                        await tx.wait();
                        console.info('User added to allowlist!', tx);

                  } else {
                        console.info('User is already in allowlist.');
                  }

            } catch (error) {
                  console.error('Error when checking or adding user to allowlist:', error);
            }
      };

      const allowToAmount = async () => {
            let activeContract;
            let saleTotalToken;
            let activePercent;

            await allowToUser();

            if (activeStage === stage1Data) {
                  activeContract = contracts;
                  saleTotalToken = "50.000.000";
                  activePercent = 0.865;
            } else if (activeStage === stage2Data) {
                  activeContract = contracts2;
                  saleTotalToken = "100.000.000";
                  activePercent = 0.825;
            } else {
                  console.error('No active stage found');
                  return;
            }

            setsaleTotalToken(saleTotalToken);

            const newAmount = parseFloat(amount) * activePercent;
            const amountWei = ethers.utils.parseUnits(newAmount.toString(), 'ether');

            try {
                  const data = await activeContract.functions?.increaseAmount(address, amountWei);
                  await data.wait();
                  console.log('Increase amount added successfully:', data);
            } catch (error) {
                  console.error('Error increasing amount for active stage:', error);
            }
      };

      const handleClaimToken = async (purchasedAmount: number) => {

            let activeContract;
            let claimPercent;

            await allowToAmount();

            if (activeStage === stage1Data) {
                  activeContract = claim1;
                  claimPercent = 0.135;
            } else if (activeStage === stage2Data) {
                  activeContract = claim2;
                  claimPercent = 0.175;
            } else {
                  console.error('No active stage found');
                  return;
            }
            const claimAmount = purchasedAmount * claimPercent;
            const claimAmountInWei = ethers.utils.parseUnits(claimAmount.toString(), 'ether');
            try {
                  const Claim = await activeContract({ args: [claimAmountInWei] });
                  console.info("contract call successs", Claim);
                  const remainingAmount = purchasedAmount - claimAmount;
                  await updateSeedBalance(remainingAmount);
            } catch (error) {
                  console.info("Claim failure!", error)
            }
      }

      const [activePercent, setactivePercent] = useState("")

      useEffect(() => {

            let activePercent: any;

            if (activeStage === stage1Data) {
                  activePercent = "13,5%";
            } else if (activeStage === stage2Data) {
                  activePercent = "17,5%";
            }
            setactivePercent(activePercent);
      }, [])

      useEffect(() => {
            const fetchBalance = async () => {
                  try {
                        const provider = new ethers.providers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545/');
                        const Address = address as string;
                        const balanceInWei = await provider.getBalance(Address);

                        const balanceInBNB = ethers.utils.formatEther(balanceInWei);
                        const ownBnbBalance = parseFloat(balanceInBNB).toFixed(6);
                        setStBalance(ownBnbBalance);
                  } catch (error) {
                  }
            };

            fetchBalance();
      }, [address]);

      const [stage1Data, setStage1Data] = useState<StageData | null>(null);
      const [stage2Data, setStage2Data] = useState<StageData | null>(null);
      const [saleTotalToken, setsaleTotalToken] = useState("")

      useEffect(() => {
            const fetchStageData = async (stageId: number) => {
                  const response = await axios.get(`https://clysterum.com/api/token?stageId=${stageId}`);
                  return response.data.message;
            };

            fetchStageData(1).then(setStage1Data);
            fetchStageData(2).then(setStage2Data);
      }, []);

      const getActiveStage = () => {
            const now = new Date().getTime();

            const isActiveStage1 = stage1Data && now >= new Date(stage1Data.startTime.replace(/ /g, "")).getTime() && now <= new Date(stage1Data.endTime.replace(/ /g, "")).getTime();
            const isActiveStage2 = stage2Data && now >= new Date(stage2Data.startTime.replace(/ /g, "")).getTime() && now <= new Date(stage2Data.endTime.replace(/ /g, "")).getTime();

            return isActiveStage1 ? stage1Data : (isActiveStage2 ? stage2Data : null);
      };

      const activeStage = getActiveStage();

      return (
            <div className='staking-main-container h-auto pt-16 pb-16'>
                  <div className="background-staking absolute top-0 left-0 w-full md:h-[800px] h-[800px] -z-30" ></div>
                  <Container>
                        <div className="staking-main-inner-container h-auto flex gap-16 pt-2 w-full md:flex-row flex-col md:px-0 px-6">
                              <div className="left-part w-full flex flex-col flex-[5] gap-8">
                                    <h1 className='md:text-6xl text-4xl font-black text-white uppercase'>Innovation of the Future</h1>
                                    <p className='px-6 py-8 border-white border rounded-2xl bg-black bg-opacity-40 text-white'>
                                          Clysterum promises a unique future in artificial intelligence and the healthcare sectors. Don't miss out on the investment opportunity by purchasing CLY Tokens and be part of this exciting journey to lead innovation!
                                          <br /><br />
                                          Sales are currently not active! Join our Telegram group or follow our social media accounts to stay updated on developments!
                                    </p>
                                    {/* <div className="buttons flex md:gap-6 gap-2">
                                          <button className='bg-white px-4 py-3 rounded-xl text-blue1 flex items-center md:gap-4 md:w-auto gap-2  font-bold md:text-base text-xs' onClick={addTokenToMetamask}><img src={MetamaskLogo} alt="metamasklogo" className='w-8 h-8' />Add CLY to MetaMask</button>
                                    </div> */}
                              </div>
                              {isActive ? (
                                    <div className="shadow-soft sale-card w-full h-full bg-white rounded-3xl relative pt-16 px-1 pb-10 flex-[6]">
                                          <div className="header absolute flex md:gap-4 py-5 blue-bg rounded-xl border-white border-2 -top-8 left-1/2 -translate-x-1/2 md:w-4/5 w-11/12 items-center md:justify-center justify-between md:px-0 px-3 text-white">
                                                <div className="total-value flex items-center md:gap-3 gap-1">
                                                      <h5 className='md:text-base text-xs'>Total Range</h5>
                                                      <h5 className='font-black md:text-lg text-base'>NULL</h5>
                                                </div>
                                                <div className='h-full md:flex hidden'>
                                                      <HiOutlineMinus className='rotate-90 md:w-8 w-4 h-auto' color='white' />
                                                </div>
                                                <div className="price flex items-center md:gap-3 gap-1">
                                                      <h5 className='md:text-base text-xs'>CLY Price</h5>
                                                      <h5 className='font-black md:text-lg text-base'>NULL</h5>
                                                </div>
                                          </div>
                                          <div className="stake-col flex flex-col gap-8 px-6">
                                                <div className="stake-row w-full flex h-auto justify-between gap-4">
                                                      <div className="left flex flex-col justify-between md:flex-[4] flex-[3]">
                                                            <h5 className='font-black blue-text md:text-xl text-base'>BUY USDT</h5>
                                                            <div className="balance-cont flex flex-col">
                                                                  <h5 className='text-sm'>Balance:</h5>
                                                                  <div className="balance-inner flex pb-2">
                                                                        <h5 className='font-bold'>{tokenBalance?.displayValue}&nbsp; </h5>
                                                                        <h5 className='font-bold'>USDT</h5>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className="right flex flex-col items-end flex-[7] gap-[6px]">
                                                            <h5 className='text-blue1 text-sm'>Max</h5>
                                                            <div className="input-and-button flex gap-0">
                                                                  <div className="input relative flex justify-end flex-[4]">
                                                                        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder='0' className='font-bold pl-4 md:pr-16 pr-10 rounded-l-xl outline-none border-blue1 border-l-2 border-t-2 border-b-2 py-2 bg-gray-100 w-full' />
                                                                        <h5 className='absolute md:right-2 right-1 md:top-[11px] top-4 text-gray-400 md:text-base text-xs'>CLY</h5>
                                                                  </div>
                                                                  {/* {isActives ? (
                                                                        <>
                                                                              {address ? (
                                                                                    <div onClick={handlePurchaseUSDT} className={`w-full py-1" + ${isActives ? "blue-bg" : "bg-gray-500"} + "rounded-r-xl text-white flex flex-row items-center justify-center gap-2 md:text-xs text-[10px] cursor-pointer md:flex-[3] flex-[4] rounded-r-xl`}>
                                                                                          <h5>Buy</h5>
                                                                                          <FaArrowRightLong />
                                                                                    </div>
                                                                              ) : (
                                                                                    // <div className={`w-full py-1" + ${isActives ? "blue-bg" : "bg-gray-500"} + "rounded-r-xl text-white flex flex-row items-center justify-center gap-2 md:text-xs text-[10px] cursor-pointer md:flex-[3] flex-[4] rounded-r-xl`}>
                                                                                    //       <h5>Connect</h5>
                                                                                    //       <FaArrowRightLong />
                                                                                    // </div>
                                                                                    <WalletConnectButton />
                                                                              )}
                                                                        </>
                                                                  ) : (
                                                                        <div className={`w-full py-1" + ${isActives ? "blue-bg" : "bg-gray-500"} + "rounded-r-xl text-white flex flex-row items-center justify-center gap-2 md:text-xs text-[10px] cursor-pointer md:flex-[3] flex-[4] rounded-r-xl`}>
                                                                              <h5>Stage is done!</h5>
                                                                        </div>
                                                                  )} */}
                                                                  {activeStage ? (
                                                                        // <>
                                                                        //       {address ? (
                                                                        //             <div onClick={handlePurchaseUSDT} className={`w-full py-1 ${activeStage ? "blue-bg" : "bg-gray-500"} rounded-r-xl text-white flex flex-row items-center justify-center gap-2 md:text-xs text-[10px] cursor-pointer md:flex-[3] flex-[4]`}>
                                                                        //                   <h5>Buy</h5>
                                                                        //                   <FaArrowRightLong />
                                                                        //             </div>
                                                                        //       ) : (
                                                                        //             <div className={`w-full py-1 bg-gray-500 rounded-r-xl text-white flex flex-row items-center justify-center gap-2 md:text-xs text-[10px] md:flex-[3] flex-[4]`}>
                                                                        //                   <h5>Please, Connect!</h5>
                                                                        //             </div>
                                                                        //       )}
                                                                        // </>
                                                                        <div className={`w-full py-1 ${activeStage ? "blue-bg" : "bg-gray-500"} rounded-r-xl text-white flex flex-row items-center justify-center gap-2 md:text-xs text-[10px] cursor-pointer md:flex-[3] flex-[4]`}>
                                                                              <h5>Stage is not active!</h5>
                                                                        </div>
                                                                  ) : (
                                                                        <div className={`w-full py-1 ${activeStage ? "blue-bg" : "bg-gray-500"} rounded-r-xl text-white flex flex-row items-center justify-center gap-2 md:text-xs text-[10px] cursor-pointer md:flex-[3] flex-[4]`}>
                                                                              <h5>Stage is done!</h5>
                                                                        </div>
                                                                  )}
                                                            </div>
                                                            <h5 className='text-blue1 text-sm'>Required</h5>
                                                      </div>
                                                </div>
                                                <div className="stake-row w-full flex h-auto justify-between gap-4">
                                                      <div className="left flex flex-col justify-between md:flex-[4] flex-[3]">
                                                            <h5 className='font-black blue-text md:text-xl text-base'>BUY 'unkown'</h5>
                                                            <div className="balance-cont flex flex-col">
                                                                  <h5 className='text-sm'>Balance:</h5>
                                                                  <div className="balance-inner flex pb-2">
                                                                        <h5 className='font-bold'>{stbalance}&nbsp; </h5>
                                                                        <h5 className='font-bold'>'unkown'</h5>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className="right flex flex-col items-end flex-[7] gap-[6px]">
                                                            <h5 className='text-blue1 text-sm'>Max</h5>
                                                            <div className="input-and-button flex gap-0">
                                                                  <div className="input relative flex justify-end flex-[4] ">
                                                                        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder='0' className='font-bold pl-4 md:pr-16 pr-10 rounded-l-xl outline-none border-blue1 border-l-2 border-t-2 border-b-2 py-2 bg-gray-100 w-full' />
                                                                        <h5 className='absolute md:right-2 right-1 md:text-base text-xs md:top-[11px] top-4 text-gray-400'>CLY</h5>
                                                                  </div>
                                                                  {activeStage ? (
                                                                        // <>
                                                                        //       {address ? (
                                                                        //             <div onClick={handlePurchaseUSDT} className={`w-full py-1 ${activeStage ? "blue-bg" : "bg-gray-500"} rounded-r-xl text-white flex flex-row items-center justify-center gap-2 md:text-xs text-[10px] cursor-pointer md:flex-[3] flex-[4]`}>
                                                                        //                   <h5>Buy</h5>
                                                                        //                   <FaArrowRightLong />
                                                                        //             </div>
                                                                        //       ) : (
                                                                        //             <div className={`w-full py-1 bg-gray-500 rounded-r-xl text-white flex flex-row items-center justify-center gap-2 md:text-xs text-[10px] md:flex-[3] flex-[4]`}>
                                                                        //                   <h5>Please, Connect!</h5>
                                                                        //             </div>
                                                                        //       )}
                                                                        // </>
                                                                        <div className={`w-full py-1 ${activeStage ? "blue-bg" : "bg-gray-500"} rounded-r-xl text-white flex flex-row items-center justify-center gap-2 md:text-xs text-[10px] cursor-pointer md:flex-[3] flex-[4]`}>
                                                                              <h5>Stage is not active!</h5>
                                                                        </div>
                                                                  ) : (
                                                                        <div className={`w-full py-1 ${activeStage ? "blue-bg" : "bg-gray-500"} rounded-r-xl text-white flex flex-row items-center justify-center gap-2 md:text-xs text-[10px] cursor-pointer md:flex-[3] flex-[4]`}>
                                                                              <h5>Stage is done!</h5>
                                                                        </div>
                                                                  )}
                                                            </div>
                                                            <h5 className='text-blue1 text-sm'>Required</h5>
                                                      </div>
                                                </div>
                                          </div>

                                          <div className="stats flex flex-col w-full px-6 pt-6">
                                                <div className="stats-container border-t-2 border-black pt-6 flex flex-col gap-1">
                                                      <h5 className='font-black blue-text text-xl'>YOUR STATS</h5>
                                                      <div className="stats-inside flex flex-col w-full">
                                                            <div className="stat flex justify-between py-2 border-b items-center">
                                                                  <h5 className='font-bold'>Opened Claim Percent</h5>
                                                                  <h5 className='font-bold'>% NULL</h5>
                                                            </div>
                                                            <div className="stat flex justify-between py-2 border-b items-center">
                                                                  <h5 className='font-bold'>Lockout Duration</h5>
                                                                  <h5 className='font-bold'>NULL</h5>
                                                            </div>
                                                            {/* <div className="stat flex justify-between py-2 border-b items-center">
                                                      <h5 className='font-bold'>My Earned CLY</h5>
                                                      <h5 className='font-bold'>0 CLY</h5>
                                                </div> */}
                                                            {/* <div className="stat flex justify-between py-2 border-b items-center">
                                                      <h5 className='font-bold flex-[6]'>Compound CLY</h5>
                                                      <div className={`md:flex-[3] flex-[5] w-auto" + ${isActives ? "blue-bg" : "bg-gray-500"} + text-white flex flex-row items-center justify-center gap-2 text-xs py-2 cursor-pointer rounded-lg`}>
                                                            <h5>Compound CLY</h5>
                                                            <FaArrowRightLong />
                                                      </div>
                                                </div>
                                                <div className="stat flex justify-between py-2 border-b items-center">
                                                      <h5 className='font-bold flex-[6]'>Harvest CLY</h5>
                                                      <div className={`md:flex-[3] flex-[5] w-auto" + ${isActives ? "blue-bg" : "bg-gray-500"} + text-white flex flex-row items-center justify-center gap-2 text-xs py-2 cursor-pointer rounded-lg`}>
                                                            <h5>Harvest CLY</h5>
                                                            <FaArrowRightLong />
                                                      </div>
                                                </div> */}
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="stats flex flex-col w-full px-6 pt-10">
                                                <div className="stats-container border-t-2 border-black pt-6 flex gap-4 items-center">
                                                      <h5 className='font-black blue-text md:text-xl text-base flex-[6]'>EARN CLY POINTS</h5>
                                                      <a href="/vesting" className='md:flex-[3] flex-[5] w-full blue-bg text-white flex flex-row items-center justify-center gap-2 text-xs px-2 cursor-pointer rounded-lg'>
                                                            <div className={`md:flex-[3] flex-[5] w-full blue-bg text-white flex flex-row items-center justify-center gap-2 text-xs py-3 px-2 cursor-pointer rounded-lg`}>
                                                                  <h5>Dashboard</h5>
                                                                  <FaArrowRightLong />
                                                            </div>
                                                      </a>
                                                </div>
                                          </div>
                                    </div>
                              ) : (
                                    <>
                                          <div className="not-logged-in md:h-[400px] h-[250px] w-full flex items-center justify-center">
                                                <div className="flex flex-col gap-2 items-center">
                                                      <div className="exclamation md:p-6 p-4 blue-bg rounded-3xl flex items-center justify-center mb-4">
                                                            <FaExclamation className="md:w-12 w-12 h-auto text-white" />
                                                      </div>
                                                      <h5 className="font-bold md:text-xl text-base text-white text-center">
                                                            Unfortunately, you haven't logged in.
                                                      </h5>
                                                      <h5 className="font-bold md:text-xl text-base text-white text-center">
                                                            You must log in to access this place!
                                                      </h5>
                                                </div>
                                          </div>
                                    </>
                              )}

                        </div>
                        <div className="profile-common flex md:px-0 mt-6 px-6">
                              <ProfileCommon />
                        </div>
                        {errorMessage && <Alert message={errorMessage} onClose={() => seterrorMessage('')} />}
                  </Container >
            </div >
      )
}

export default StakingMain
