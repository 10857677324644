import React from 'react'
import Hero from '../components/home/Hero'
import HowItWorks from '../components/home/HowItWorks'
import ForPart from '../components/home/ForPart'
import LastText from '../components/home/LastText'



function Home() {
      return (
            <div className='home'>
                  <Hero />
                  <ForPart />
                  <HowItWorks />
                  <LastText />
            </div>
      )
}

export default Home

