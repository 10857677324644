import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useDisconnect } from '@thirdweb-dev/react';
import Alert from '../components/common/Alert';

interface User {
    email: string;
    walletAddress: string;
    seedBalance: number;
    totalCly: number;
    accessToken: string;
    isActive: boolean;
}

interface ClysterumData {
    stage: number;
    status: string | null;
    name: string;
    tokenPriceUSDT: number;
    totalTokens: number;
    tokensSold: number;
    totalTargetUSDT: number;
    startTime: string;
    endTime: string;
}

interface AuthContextType {
    user: User | null;
    isActive: boolean;
    login: (email: string, password: string) => Promise<void>;
    sendOtp: (email: string) => Promise<void>;
    verifyOtp: (otp: string) => Promise<void>;
    logout: () => void;
    fetchAndUpdateUserDetails: (accessToken: string) => Promise<void>;
    updateWalletAddress: (walletAddress: string) => Promise<void>;
    updateSeedBalance: (newSeedBalance: number) => Promise<void>;
    decreaseTotalCly: (amountToDecrease: number) => Promise<void>;
    clysterumData: ClysterumData | null;
    updateTokensSold: (stageId: number, tokensSold: number) => Promise<void>;
    updateStatus: (stageId: number, status: string) => Promise<void>;
    setWalletType: (walletType: string) => void;
    setWalletLogo: (walletLogo: string) => void;
    walletType?: string;
    walletLogo?: string;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [isActive, setIsActive] = useState<boolean>(false);
    const disconnect = useDisconnect();
    const [clysterumData, setClysterumData] = useState<ClysterumData | null>(null);
    const [alertMessage, setAlertMessage] = useState<string | null>(null);
    const [walletType, setWalletType] = useState<string>('');
    const [walletLogo, setWalletLogo] = useState<string>('');

    localStorage.setItem('walletType', walletType);
    localStorage.setItem('walletLogo', walletLogo);

    useEffect(() => {
        const storedUser = Cookies.get('user');
        if (storedUser) {
            const userData = JSON.parse(storedUser);
            setUser(userData);
            setIsActive(userData.isActive || false);
        }
    }, []);

    const login = useCallback(async (email: string, password: string) => {
        try {
            const response = await axios.post('https://app.clysterum.com/users/login', { email, password });

            if (response.data) {
                const { email, walletAddress, seedBalance, totalCly, tokens } = response.data;
                const user = {
                    email,
                    walletAddress,
                    seedBalance,
                    totalCly,
                    accessToken: tokens.acces_token,
                    isActive: false,
                };

                setUser(user);
                Cookies.set('user', JSON.stringify(user), { sameSite: 'Lax', secure: false, expires: 1 / 24 });
            }
        } catch (error) {
            console.error('Login failed:', error);
            throw new Error('Login failed');
        }
    }, []);

    const sendOtp = useCallback(async (email: string) => {
        try {
            const response = await axios.post('https://clysterum.com/api/send-otp/', { email });
            if (response.status === 200) {
                console.log('OTP sent successfully');
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            if (axios.isAxiosError(error) && error.response) {
                const errorMessage = error.response.data.message || "Failed to sending OTP. Please try again.";
                showAlert(errorMessage);
            } else {
                showAlert("Failed to sending OTP. Please try again.");
            }
        }
    }, []);

    const verifyOtp = useCallback(async (otp: string) => {
        try {
            const response = await axios.post('https://clysterum.com/api/verify-otp/', {
                email: user?.email,
                otp,
            });

            if (response.status === 200) {
                console.log('OTP verified successfully');
                setIsActive(true);
                if (user) {
                    Cookies.set('user', JSON.stringify({ ...user, isActive: true }), { sameSite: 'Lax', secure: false, expires: 1 });
                }
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            setIsActive(false);
            if (axios.isAxiosError(error) && error.response) {
                const errorMessage = error.response.data.message || "Failed to verify OTP. Please try again.";
                showAlert(errorMessage);
            } else {
                showAlert("Failed to verify OTP. Please try again.");
            }
        }
    }, [user?.email]);

    const fetchAndUpdateUserDetails = useCallback(async (accessToken: string) => {
        try {
            const response = await axios.post('https://app.clysterum.com/users/user-detail', {}, {
                headers: { 'x-acces-token': accessToken }
            });

            if (response.status === 200) {
                const { email, walletAddress, seedBalance, totalCly } = response.data;
                const updatedUser = { ...user, email, walletAddress, seedBalance, totalCly, accessToken, isActive: true };
                setUser(updatedUser);
                Cookies.set('user', JSON.stringify(updatedUser), { sameSite: 'None', secure: true, expires: 1 / 24 });
                setIsActive(true);
            }
        } catch (error) {
            console.error('Error fetching user details', error);
        }
    }, [user]);

    const updateWalletAddress = useCallback(async (newWalletAddress: string) => {
        if (!user || !user.accessToken) return;

        try {
            await axios.post('https://app.clysterum.com/users/update-wallet', { walletAddress: newWalletAddress }, {
                headers: { 'x-acces-token': user.accessToken }
            });
            await fetchAndUpdateUserDetails(user.accessToken);
        } catch (error) {
            console.error('Error updating wallet address', error);
        }
    }, [user, fetchAndUpdateUserDetails]);

    const updateSeedBalance = useCallback(async (newSeedBalance: number) => {
        if (!user || !user.accessToken) return;

        try {
            await axios.post('https://app.clysterum.com/users/update-seed-balance', { seedBalance: newSeedBalance }, {
                headers: { 'x-acces-token': user.accessToken }
            });
            await fetchAndUpdateUserDetails(user.accessToken);
        } catch (error) {
            console.error('Error updating seed balance', error);
        }
    }, [user, fetchAndUpdateUserDetails]);

    const decreaseTotalCly = useCallback(async (amountToDecrease: number) => {
        if (!user || !user.accessToken) return;

        try {
            await axios.post('https://app.clysterum.com/users/decrease-cly', { cly: amountToDecrease }, {
                headers: { 'x-acces-token': user.accessToken }
            });
            await fetchAndUpdateUserDetails(user.accessToken);
        } catch (error) {
            console.error('Error decreasing total Cly', error);
        }
    }, [user, fetchAndUpdateUserDetails]);

    useEffect(() => {
        axios.get('https://clysterum.com/api/token?stageId=1')
            .then(response => {
                const data: ClysterumData = response.data.message;
                setClysterumData(data);
            })
            .catch(error => {
                console.error('Clysterum data fetch error:', error);
            });
    }, []);

    const updateTokensSold = useCallback(async (stageId: number, tokensSold: number) => {
        const data = JSON.stringify({
            stageId,
            tokensSold
        });

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://clysterum.com/api/token',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        try {
            const response = await axios(config);
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    }, []);

    const updateStatus = useCallback(async (stageId: number, status: string) => {
        const data = JSON.stringify({
            stageId,
            status
        });

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://clysterum.com/api/token',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        try {
            const response = await axios(config);
        } catch (error) {
            console.error(error);
        }
    }, []);

    const fetchTokenData = useCallback(async () => {
        try {
            const [responseStage1, responseStage2] = await Promise.all([
                axios.get('https://clysterum.com/api/token?stageId=1'),
                axios.get('https://clysterum.com/api/token?stageId=2'),
            ]);

            const dataStage1 = responseStage1.data.message;
            const dataStage2 = responseStage2.data.message;

            const endTimeStage1 = new Date(dataStage1.endTime.replace(" ", "")).getTime();
            const endTimeStage2 = new Date(dataStage2.endTime.replace(" ", "")).getTime();
            const now = new Date().getTime();

            let activeStageData;

            if (endTimeStage1 > now && (endTimeStage1 < endTimeStage2 || endTimeStage2 <= now)) {
                activeStageData = dataStage1;
            } else if (endTimeStage2 > now) {
                activeStageData = dataStage2;
            }

            if (activeStageData) {
                if (activeStageData.tokensSold >= activeStageData.totalTokens || now >= new Date(activeStageData.endTime).getTime()) {

                    await updateStatus(activeStageData.stage, 'ended');
                    activeStageData.status = 'ended';
                } else {
                    await updateStatus(activeStageData.stage, 'active');
                    activeStageData.status = 'active';
                }

                setClysterumData(activeStageData);
            }
        } catch (error) {
            console.error('Error fetching token data:', error);
        }
    }, [updateStatus]);

    useEffect(() => {
        fetchTokenData();
    }, [fetchTokenData]);

    const logout = useCallback(() => {
        setUser(null);
        Cookies.remove('user');
        setIsActive(false);
        disconnect();
    }, []);

    const showAlert = useCallback((message: string) => {
        setAlertMessage(message);
        setTimeout(() => setAlertMessage(null), 3000);
    }, []);

    const closeAlert = useCallback(() => {
        setAlertMessage(null);
    }, []);

    const value = {
        user,
        isActive,
        login,
        sendOtp,
        verifyOtp,
        logout,
        fetchAndUpdateUserDetails,
        updateWalletAddress,
        updateSeedBalance,
        decreaseTotalCly,
        clysterumData,
        updateTokensSold,
        updateStatus,
        walletType,
        setWalletType,
        walletLogo,
        setWalletLogo,
    };

    return <AuthContext.Provider value={value}>
        {children}
        {alertMessage && <Alert message={alertMessage} onClose={closeAlert} />}
    </AuthContext.Provider>;
};

export default AuthProvider;
