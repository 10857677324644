import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConnectWallet, ThirdwebProvider, metamaskWallet, trustWallet, walletConnect } from "@thirdweb-dev/react";
import "./index.css";
import ReactDOM from 'react-dom/client';
import AuthProvider from './context/AuthContext';
import { REACT_APP_TW_SECRET_KEY, REACT_APP_TEMPLATE_CLIENT_ID } from './const/ContractAddresses';
import { BrowserRouter } from "react-router-dom";

// This is the chain your dApp will work on.
// Change this to the chain your app is built for.
// You can also import additional chains from `@thirdweb-dev/chains` and pass them directly.
const activeChain = "binance-testnet";

// const container = document.getElementById("root");
// const root = createRoot(container!);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThirdwebProvider
      activeChain={activeChain}
      supportedWallets={[metamaskWallet({
        projectId: "7fa837bc1e5effc88e18989c7474c9a8",
        connectionMethod: "metamaskBrowser",
        recommended: true,
      }), trustWallet(), walletConnect({
        projectId: "7fa837bc1e5effc88e18989c7474c9a8",
        qrModal: "walletConnect",
        qrModalOptions: {
          themeMode: "light",
        },
        recommended: true,
      })]}
      clientId={REACT_APP_TEMPLATE_CLIENT_ID}
    >
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThirdwebProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();