
import React from 'react'
import Container from '../common/Container'
import ProfileCommon from '../../pages/ProfileDashboard/ProfileCommon'

function LastText() {
      return (
            <div className='last-text-container mb-20'>
                  <Container>
                        <div className="last-text-inner-container flex justify-between gap-4 md:h-[400px] h-[500px] w-full items-center md:mb-6 mb-0 md:flex-row flex-col">
                              <div className="text md:flex-[1] flex-[1] flex items-center h-full w-full justify-center mt-12">
                                    <h5 className='md:text-5xl text-4xl font-bold text-center text-clysblack'>HOW IT WORKS</h5>
                              </div>
                              <div className="md:flex-[1] flex-[8] youtube-video-inner-container md:shadow-soft md:w-full w-11/12 h-auto items-center justify-center md:rounded-3xl mx-auto mt-12">
                                    <iframe className='w-full md:h-[300px] h-[265px] rounded-xl border-none border-0' src="https://www.youtube.com/embed/mT7KrDIWKXU" title="Clysterum adım sayar nasıl aktif edilir ve kayıt nasıl oluşturulur?" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}></iframe>
                              </div>
                        </div>
                        <div className="profile-common md:w-full flex items-center justify-center w-11/12 mx-auto">
                              <ProfileCommon />
                        </div>
                  </Container>
            </div>
      )
}

export default LastText
