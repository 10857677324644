import React, { useEffect, useState } from 'react'
import { FaArrowRight } from 'react-icons/fa6'
import { MdOutlineEmail } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import { LuEye } from "react-icons/lu";
import { LuEyeOff } from "react-icons/lu";
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

function Login() {
      const navigate = useNavigate();
      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const { isActive, login, sendOtp } = useAuth();
      const [loginError, setLoginError] = useState('');

      useEffect(() => {
            if (isActive) {
                  navigate('/');
            }
      }, [isActive, navigate]);

      const handleSubmit = async (event: React.FormEvent) => {
            event.preventDefault();
            setLoginError('');
            try {
                  await login(email, password);
                  await sendOtp(email);
                  navigate('/verify');
            } catch (error) {
                  setLoginError('Email or Password is invalid!');
            }
      };

      const handlePressEnter = async (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                  handleSubmit(e);
            }
      }

      const [canSeePassword, setCanSeePassword] = useState(false);

      return (
            <div className='login max-w-[600px] md:w-full w-11/12 h-auto bg-white shadow-soft mx-auto md:mt-12 mt-4 md:mb-24 mb-12 flex flex-col rounded-3xl gap-6 pb-20'>
                  <div className="top-part w-full blue-bg flex items-center justify-center rounded-3xl py-6 md:px-4 px-8 flex-col gap-4">
                        <h5 className='md:text-3xl text-xl font-bold text-white text-center'>WELCOME TO THE FUTURE OF CLYSTERUM</h5>
                        <div className="line h-[2px] w-40 bg-white"></div>
                  </div>
                  <div className="middle-part flex flex-col w-full px-8 gap-4 mt-10">
                        <div className="input w-full flex flex-col gap-[2px]">
                              <h5 className='text-clysblack font-bold'>Email</h5>
                              <div className="input-container relative w-full">
                                    <MdOutlineEmail className='w-5 h-auto absolute text-gray-400 top-[10px] left-4' />
                                    <input type="text" value={email} onKeyPress={handlePressEnter} onChange={(e) => setEmail(e.target.value)} className='outline-none py-2 bg-slate-200 rounded-md w-full pl-[50px]' placeholder='Email' />
                              </div>
                        </div>
                        <div className="input w-full flex flex-col gap-[2px]">
                              <h5 className='text-clysblack font-bold'>Password</h5>
                              <div className="input-container relative w-full">
                                    <FaLock className='w-5 h-auto absolute text-gray-400 top-[10px] left-4' />
                                    <input type={`${canSeePassword ? 'text' : 'password'}`} onKeyPress={handlePressEnter} value={password} onChange={(e) => setPassword(e.target.value)}  className={`outline-none py-2 bg-slate-200 rounded-md w-full pl-[50px]`} placeholder='·······' />
                                    <button className='absolute right-4 top-2' onClick={() => setCanSeePassword(!canSeePassword)}>{canSeePassword ? <LuEye className='w-6 h-auto text-gray-400' /> : <LuEyeOff className='w-6 h-auto text-gray-400' />}</button>
                              </div>
                        </div>
                  </div>
                  <div className="bottom-part flex flex-col w-full px-8 mt-4 items-center">
                        <button className='flex items-center justify-center py-2 rounded-md text-white gap-2 text-sm w-full' onClick={handleSubmit}>Login<FaArrowRight /></button>
                        {loginError && <div className="alert">{loginError}</div>}
                        <div className="texts flex flex-col gap-4 mt-6 items-center text-center text-clysblack">
                              <h5>Use your clysterum app credentials to login</h5>
                              <div className="sing-up-text flex gap-2">
                                    <h5>Not a Member?</h5>
                                    <a href="https://bit.ly/cly-app">Sign Up</a>
                              </div>
                        </div>
                  </div>
            </div>
      )
}

export default Login;
