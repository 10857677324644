import React from 'react'
import ProfileMain from '../../components/profile/ProfileMain'

function ProfileSeedSale() {
      return (
            <ProfileMain number={2}>
                  <div className="main-part w-full max-h-full h-full flex flex-col gap-6">
                        <div className="profile-title flex">
                              <h5 className='text-3xl font-bold flex'>SEED SALE</h5>
                        </div>
                        <div className="profile-main-part-container flex w-full h-full flex-col gap-4">
                              <div className="up-part flex-1 w-full h-full bg-red-400"></div>
                              <div className="bottom-part flex-1 w-full h-full bg-red-400"></div>
                        </div>
                  </div>
            </ProfileMain>
      )
}

export default ProfileSeedSale
