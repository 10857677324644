import React, { useEffect, useState } from 'react';
import Container from './Container';
import Logo from '../../images/logo.png';
import { FaUserLarge } from "react-icons/fa6";
import { FiMenu } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import CoinLogo from '../../images/coinlogo.png';
import { useAddress, ConnectWallet, useDisconnect, useContract, useTokenBalance, useWalletConnect } from '@thirdweb-dev/react';

import { IoCopyOutline } from "react-icons/io5";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { RiDoorOpenLine } from "react-icons/ri";

import { tokenContract, url } from '../../const/ContractAddresses';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Alert from '../common/Alert';
import ethers from 'ethers';
import WalletConnectButton from './ConnectButton/WalletConnectButton';

function Header() {
      const { logout, isActive, user, updateWalletAddress, walletType, walletLogo } = useAuth();
      const [isMenu, setIsMenu] = useState(false);
      const [isPopUp, setIsPopUp] = useState(false);
      const [copied, setCopied] = useState(false);
      const address = useAddress();
      const disconnect = useDisconnect();
      const walletAddress = address;
      const { contract: tokenContracts } = useContract(tokenContract);
      const { data: balance } = useTokenBalance(tokenContracts, walletAddress);
      const [alertMessage, setalertMessage] = useState("")
      const [showLoginButton, setShowLoginButton] = useState(false);
      const [windowWidth, setWindowWidth] = useState(window.innerWidth);

      function kisaltAdres(adres: string, baslangicUzunlugu: number = 4, sonUzunlugu: number = 4): string {
            if (adres.length <= baslangicUzunlugu + sonUzunlugu) {
                  return adres;
            }
            return `${adres.substring(0, baslangicUzunlugu)}...${adres.substring(adres.length - sonUzunlugu)}`;
      }

      const kisaltılmısAdres = walletAddress ? kisaltAdres(walletAddress) : "Adres Yok";

      const handleMenu = () => {
            setIsMenu(!isMenu);
            setShowLoginButton(!isMenu || !isActive);
      };

      const handleDisconnect = () => {
            disconnect();
            setIsPopUp(false);
      };

      useEffect(() => {
            if (address && user?.walletAddress && user.walletAddress !== address) {
                  setalertMessage("Please, use a wallet address that you have registered before and is unique.");
                  handleDisconnect();
            }
      }, [address, user?.walletAddress, handleDisconnect]);

      const handleCopyAddress = async () => {

            if (walletAddress) {
                  await navigator.clipboard.writeText(walletAddress);
            } else {
                  console.error("Unkown address!");
            }

            try {
                  if (walletAddress) {
                        await navigator.clipboard.writeText(walletAddress);
                        console.log('Address copied to clipboard!');
                        setCopied(true);
                  } else {
                        console.error("Wallet address unkown!");
                  }
            } catch (error) {
                  console.error("Clipboard'a kopyalama sırasında hata oluştu:", error);
            }
            finally {
                  setTimeout(() => {
                        setCopied(false);

                  }, 1500);
            }
      };

      useEffect(() => {
            if (isPopUp) {
                  setIsMenu(false);
                  document.documentElement.classList.add('no-scroll');
                  document.body.classList.add('no-scroll');
            } else {
                  document.documentElement.classList.remove('no-scroll');
                  document.body.classList.remove('no-scroll');
            }

            return () => {
                  document.documentElement.classList.remove('no-scroll');
                  document.body.classList.remove('no-scroll');
            };
      }, [isPopUp]);

      useEffect(() => {
            const fetchUserDetails = async () => {
                  if (address && user?.walletAddress === "") {
                        try {
                              updateWalletAddress(address);
                        } catch (error) {
                              console.log('Please, Connect your Metamask!')
                        }
                  }
            };
            fetchUserDetails();
      }, [address, user?.walletAddress, updateWalletAddress]);

      useEffect(() => {
            const handleResize = () => {
                  setWindowWidth(window.innerWidth);
            };

            window.addEventListener('resize', handleResize);

            // Cleanup
            return () => {
                  window.removeEventListener('resize', handleResize);
            };
      }, []);

      const isMobile = () => windowWidth <= 768;

      const savedWalletType = localStorage.getItem('walletType');
      const savedWalletLogo = localStorage.getItem('walletLogo');

      return (
            <header>
                  {isPopUp
                        ?
                        <>
                              <div className="md:block hidden pop-up-bg w-full h-full absolute bg-black opacity-85 z-30 cursor-pointer" onClick={() => setIsPopUp(!isPopUp)}>

                              </div>
                              <div className="pop-up absolute centered max-w-[650px] md:w-full w-11/12 h-auto bg-white rounded-3xl z-40 flex p-8 flex-col gap-6 items-center">
                                    <div className="top-part w-full justify-between flex">
                                          <h5 className='text-xl font-bold'>Account</h5>
                                          <button onClick={() => setIsPopUp(!isPopUp)} className='h-8'>
                                                <IoCloseOutline className='w-8 h-8' />
                                          </button>
                                    </div>
                                    <div className="part flex blue-bg w-full rounded-xl items-center py-4 px-6 gap-6 md:flex-row flex-col">
                                          <div className="left-part">
                                                <div className="img w-20 h-20 rounded-full bg-white flex items-center justify-center p-4">
                                                      <img src={CoinLogo} alt="coin" className='w-full h-auto' />
                                                </div>
                                          </div>
                                          <div className="right-part w-full flex text-white justify-between">
                                                <div className="left flex flex-col">
                                                      <h5 className='md:text-base text-sm'>Balance</h5>
                                                      <h5 className='font-bold md:text-base text-sm'>{balance?.displayValue} CLY</h5>
                                                </div>
                                                <div className="left flex flex-col">
                                                      <h5 className='md:text-base text-sm'>Network</h5>
                                                      <h5 className='font-bold md:text-base text-sm'>BNBChain</h5>
                                                </div>
                                                <div className="left flex flex-col">
                                                      <h5 className='md:text-base text-sm'>Wallet</h5>
                                                      <h5 className='font-bold md:text-base text-sm'>{savedWalletType}</h5>
                                                </div>
                                          </div>
                                    </div>
                                    <div className="wallet-address-part w-full py-2 md:px-6 px-1 bg-slate-200 rounded-md items-center gap-4 md:flex hidden">
                                          <div className="wallet-img w-8 h-8 rounded-full border border-black flex items-center justify-center p-1">
                                                <img src={savedWalletLogo || 'default-wallet-logo.png'} alt="wallet-icon" className='w-full h-full' />
                                          </div>
                                          <div className="address">
                                                <h5 className='md:font-bold font-medium md:text-base text-xs'>
                                                      {walletAddress}
                                                </h5>
                                          </div>
                                    </div>
                                    <div className="bottom-part2 flex justify-between md:gap-4 gap-2 w-full">

                                          <div className="part w-full h-12 rounded-xl shadow-soft flex flex-col items-center justify-center gap-2 cursor-pointer">
                                                <button className='flex items-center justify-center w-full h-full gap-2 hover:text-blue1' onClick={handleCopyAddress}>
                                                      <IoCopyOutline className='md:w-6 w-4 h-auto' />
                                                      <h5 className='text-xs md:text-sm text-center px-1 cursor-pointer'>Copy Address</h5>
                                                </button>
                                          </div>
                                          <div className="part w-full h-12 rounded-xl shadow-soft flex flex-col items-center justify-center gap-2 cursor-pointer">
                                                <a href={`https://bscscan.com/address/${walletAddress}`} target='_blank' className='w-full h-full flex items-center justify-center gap-2 hover:text-blue1'>
                                                      <BsBoxArrowUpRight className='md:w-5 w-4 h-auto' />
                                                      <h5 className='text-xs md:text-sm text-center'>View in Explorer</h5>
                                                </a>
                                          </div>

                                          <div className="part w-full h-12 rounded-xl shadow-soft flex flex-col items-center justify-center gap-2 cursor-pointer">
                                                <a href="#" className='w-full h-full flex items-center justify-center gap-2 hover:text-blue1'>
                                                      <RiDoorOpenLine className='md:w-6 w-4 h-auto' />
                                                      <h5 className='text-xs md:text-sm text-center' onClick={handleDisconnect}>Disconnect</h5>
                                                </a>
                                          </div>
                                    </div>
                              </div>
                              {
                                    copied ? (
                                          <div className="text-copied active w-auto h-12 bg-green-400 rounded-lg flex items-center justify-center absolute px-10 z-40 centered-x">
                                                <h5 className='font-bold'>Address copied!</h5>
                                          </div>
                                    ) : (
                                          <div className="text-copied w-auto h-12 bg-green-400 rounded-lg flex items-center justify-center absolute px-10 z-40 centered-x">
                                                <h5 className='font-bold'>Address copied!</h5>
                                          </div>
                                    )
                              }
                        </>
                        :
                        <></>
                  }
                  <div className="header-container w-full h-[88px] flex bg-white">
                        <Container>
                              <div className="md:px-0 px-6 header-inner-container w-full h-full flex items-center justify-between">
                                    <div className="logo h-full w-auto">
                                          <a href="/">
                                                <img src={Logo} alt="logo" className='h-full p-6 w-auto' />
                                          </a>
                                    </div>
                                    <div className="links-and-button h-full w-auto flex items-center gap-8">
                                          <div className="links h-full w-auto items-center gap-10 text-[15px] md:flex hidden">
                                                <a href="/" className='font-bold text-clysblack'>Home</a>
                                                <a href="/sales" className='font-bold text-clysblack'>Sales</a>
                                                <a href="/airdrop" className='font-bold text-clysblack'>Airdrop</a>
                                                <a href="/vesting" className='font-bold text-clysblack'>Withdrawal</a>
                                          </div>
                                          <div className="connect-button flex md:gap-8 gap-6 items-center">
                                                {isActive ? (
                                                      <>
                                                            {walletAddress ? (
                                                                  <>
                                                                        <div className="button-container w-full border-2 border-blue1 rounded-xl h-10 items-center justify-between bg-gray-100 md:flex hidden cursor-pointer" onClick={() => setIsPopUp(!isPopUp)}>
                                                                              <div className="left h-10 items-center flex px-3">
                                                                                    <h5 className='font-bold'>
                                                                                          {balance?.displayValue} CLY
                                                                                    </h5>
                                                                              </div>
                                                                              <div className="right border-t-2 border-l-2 border-b-2 border-blue1 rounded-xl h-10 items-center flex px-4 gap-4">
                                                                                    <h5 className='font-bold'>
                                                                                          {kisaltılmısAdres}
                                                                                    </h5>
                                                                                    <div className="image w-7 h-7 rounded-full border  p-1">
                                                                                          <img src={walletLogo} alt="wallet-icon" className='w-full h-full' />
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                        <a href="/profile">
                                                                              <button className='user w-12 h-12 rounded-full bg-white border-2 flex items-center justify-center'>
                                                                                    <FaUserLarge className='user-icon w-[18px] h-[18px]' />
                                                                              </button>
                                                                        </a>
                                                                  </>

                                                            ) : (
                                                                  <>
                                                                        {isMobile() ? (
                                                                              null
                                                                        ) : (
                                                                              <>
                                                                                    <WalletConnectButton />
                                                                                    <a href="/profile">
                                                                                          <button className='user w-12 h-12 rounded-full bg-white border-2 flex items-center justify-center'>
                                                                                                <FaUserLarge className='user-icon w-[18px] h-[18px]' />
                                                                                          </button>
                                                                                    </a>
                                                                              </>
                                                                        )}
                                                                  </>
                                                            )}
                                                      </>
                                                ) : (
                                                      <a href="/login" className='font-bold text-white'>
                                                            <button className='connect px-8 py-3 rounded-lg text-white text-sm md:block hidden'>
                                                                  Login
                                                            </button>
                                                      </a>
                                                )}
                                                <button
                                                      className="md:hidden flex items-center justify-center"
                                                      onClick={handleMenu}
                                                >
                                                      {isMenu ? (
                                                            <IoCloseOutline
                                                                  className="w-[44px] h-[44px]"
                                                                  color="#0f1355"
                                                            />
                                                      ) : (
                                                            <FiMenu
                                                                  className="w-[44px] h-[44px]"
                                                                  color="#0f1355"
                                                            />
                                                      )}
                                                </button>
                                          </div>
                                    </div>
                              </div>
                        </Container>
                  </div>
                  <div
                        className={`mobile-links-container mobile-links w-full pl-8 pr-8 gap-6 text-base md:hidden flex flex-col absolute top-[88px] blue-bg text-white border-b-2 border-b-white z-50 ${isMenu ? "active" : ""
                              }`}>
                        <a className='mt-10' href="/">HOME</a>
                        <a href="/sales">SALES</a>
                        <a href="/airdrop">AIRDROP</a>
                        <a href="/vesting">WITHDRAWAL</a>
                        {showLoginButton && isActive ? (
                              <>
                                    {walletAddress ? (
                                          <>
                                                <div className="button-container w-full border-2 border-blue1 rounded-xl h-12 items-center justify-between bg-gray-100 flex text-black cursor-pointer" onClick={() => setIsPopUp(!isPopUp)}>
                                                      <div className="left h-10 items-center flex px-3">
                                                            <h5 className='font-bold'>
                                                                  {balance?.displayValue} CLY
                                                            </h5>
                                                      </div>
                                                      <div className="right border-t-2 border-l-2 border-b-2 border-blue1 rounded-xl h-12 items-center flex px-4 gap-4">
                                                            <h5 className='font-bold'>
                                                                  {kisaltılmısAdres}
                                                            </h5>
                                                            <div className="image w-7 h-7 rounded-full border p-1">
                                                                  <img src={walletLogo} alt="wallet-icon" className='w-full h-full' />
                                                            </div>
                                                      </div>
                                                </div>
                                                {/* <a href="/profile">
                                                      <button className='user w-12 h-12 rounded-full bg-white border-2 flex items-center justify-center'>
                                                            <FaUserLarge className='user-icon w-[18px] h-[18px]' />
                                                      </button>
                                                </a> */}
                                          </>
                                    ) : (
                                          <WalletConnectButton />
                                    )}
                              </>
                        ) : (
                              <>
                                    <a href="/login" className='font-bold text-white'>
                                          <button className='connect px-8 py-3 rounded-lg bg-white border-2 text-black text-sm md:block'>
                                                Login
                                          </button>
                                    </a>
                              </>
                        )}
                  </div >
                  {alertMessage && <Alert message={alertMessage} onClose={() => setalertMessage('')} />}
            </header >
      );
}

export default Header;