import React, { useEffect, useState } from 'react'
import Container from '../common/Container'
import CoinLogo from '../../images/coinlogo.png'
import MetamaskLogo from "../../images/MetaMask_Fox.svg.png"
import { BiLogoTelegram } from "react-icons/bi";
import { BsTwitterX } from "react-icons/bs";
import { FaDiscord } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaArrowRightLong } from 'react-icons/fa6';
import ClysLogo from '../../images/clysterumlogo.png';
import { ConnectWallet, useAddress, useDisconnect, useConnect, metamaskWallet } from '@thirdweb-dev/react';
import { useAuth } from '../../context/AuthContext';
import saleStages from '../../data/saleStage';
import axios from 'axios';

interface StageData {
      startTime: string;
      endTime: string;
}

function Hero() {

      const { isActive, logout, clysterumData } = useAuth();
      const address = useAddress();
      const disconnect = useDisconnect();
      const [bnbPrice, setBnbPrice] = useState<any>();

      useEffect(() => {
            fetch('https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT')
                  .then((response) => response.json())
                  .then((data) => {
                        setBnbPrice(data.price);
                  })
                  .catch((error) => {
                        console.error('Error fetching BNB price:', error);
                  });
      }, []);

      const tokenPriceInBNB = clysterumData ? clysterumData.tokenPriceUSDT / bnbPrice : 0;

      const calculateSalePercentage = (tokensSold: any, totalTokens: any) => {
            return ((tokensSold / totalTokens) * 100).toFixed(2);
      };

      const salePercentage = clysterumData ? calculateSalePercentage(clysterumData.tokensSold, clysterumData.totalTokens) : '0';
      const totalRaised = clysterumData ? clysterumData.tokensSold * clysterumData.tokenPriceUSDT : 0;
      const style = { width: `${salePercentage}%` };

      const calculateTimeLeft = () => {
            if (!clysterumData) return null;

            const endTimeString = clysterumData.endTime.replace(' ', '');
            const endTime = new Date(endTimeString).getTime();
            const now = new Date().getTime();

            if (endTime < now) {
                  return "00-00-0000 00:00";
            }

            const diff = endTime - now;
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((diff / 1000 / 60) % 60);
            const seconds = Math.floor((diff / 1000) % 60);

            return `${days}d ${hours}h ${minutes}m`;
      };

      const timeLeft = calculateTimeLeft();

      const getStatusMessage = () => {
            if (!clysterumData) return 'Loading...';

            const now = new Date().getTime();
            const startTime = new Date(clysterumData.startTime.replace(" ", "")).getTime();
            const endTime = new Date(clysterumData.endTime.replace(" ", "")).getTime();

            if (now < startTime) {
                  return 'COMING SOON';
            } else if (now >= startTime && now <= endTime) {
                  return 'ACTIVE';
            } else if (now > endTime) {
                  return 'ENDED';
            }
      };

      const statusMessage = getStatusMessage();

      const getStageMessage = () => {
            if (!clysterumData) return 'Loading...';

            const now = new Date().getTime();
            const startTime = new Date(clysterumData.startTime.replace(" ", "")).getTime();
            const endTime = new Date(clysterumData.endTime.replace(" ", "")).getTime();
            const name = clysterumData.name;
            if (now < startTime) {
                  return name;
            } else if (now >= startTime && now <= endTime) {
                  return name;
            } else if (now > endTime) {
                  return name;
            }
      };

      const stageMessage = getStageMessage();

      return (
            <div className='hero-container w-full h-auto'>
                  <div className="bg-image absolute top-16 left-0 h-[700px] w-full -z-10"></div>
                  <Container>
                        <div className="hero-inner-container flex md:flex-row flex-col w-full h-full items-center md:gap-20 gap-10 md:pb-18 pb-0 md:justify-start md:mt-12 mt-0 justify-center md:px-0 px-6">
                              <div className="hero-left flex-1 w-full h-3/5 flex flex-col md:gap-8 gap-4 justify-center md:mb-20 mb-0 md:py-0 mt-16">
                                    <div className="text-part flex flex-col md:gap-8 gap-4">
                                          <h5 className='text-white md:text-5xl text-4xl font-black'>CLYSTERUM TOKEN</h5>
                                          <p className='text-white w-11/12'>Clysterum offers a unique future in AI and healthcare. You can invest early by purchasing CLY Token in the seed round.</p>
                                    </div>
                                    <div className="buttons flex flex-col gap-2">
                                          <div className="button-container flex md:gap-6 gap-4">
                                                {isActive ? (
                                                      <button onClick={logout} className='md:w-40 w-32 h-[56px] bg-gray-100 rounded-lg bg-opacity-100 hover:bg-gray-200 md:font-bold font-semibold md:text-base text-sm text-black'>
                                                            LOGOUT
                                                      </button>
                                                ) : (
                                                      <a href="/login">
                                                            <button className='md:w-40 w-32 h-[56px] bg-gray-100 rounded-lg bg-opacity-100 hover:bg-gray-200 md:font-bold font-semibold md:text-base text-sm text-black'>
                                                                  LOGIN
                                                            </button>
                                                      </a>

                                                )}
                                                <a href="/sales">
                                                      <button className='md:w-56 w-44 h-[56px] bg-gray-100 rounded-lg bg-opacity-100 hover:bg-gray-200 md:font-bold font-semibold md:text-base text-sm text-black'>
                                                            SALE
                                                      </button>
                                                </a>
                                          </div>
                                          <div className="button-container flex md:gap-6 gap-4">
                                                <a href="/airdrop">
                                                      <button className='md:w-40 w-32 h-[56px] bg-slate-900 rounded-lg bg-opacity-100 hover:bg-slate-800 md:font-bold font-semibold md:text-base text-sm text-white'>
                                                            AIRDROP
                                                      </button>
                                                </a>
                                                <a href="https://bit.ly/cly-app" target='blank'>
                                                      <button className='md:w-56 w-44 h-[56px]  bg-slate-900 rounded-lg bg-opacity-100 hover:bg-slate-800 md:font-bold font-semibold md:text-base text-sm text-white'>
                                                            DOWNLOAD APP
                                                      </button>
                                                </a>
                                          </div>
                                    </div>
                                    <div className="socials flex gap-4 w-full items-start flex-col">
                                          <h5 className='text-white text-sm'>Follow our social media accounts:</h5>
                                          <div className="icons flex gap-6">
                                                <a href="https://t.me/clysterum" target='blank' className='cursor-pointer'>
                                                      <BiLogoTelegram className='w-auto h-8 text-white cursor-pointer' />
                                                </a>
                                                <a href="https://twitter.com/clysterum" target='blank'>
                                                      <BsTwitterX className='w-auto h-6 text-white' />
                                                </a>
                                                <a href="https://discord.com/invite/k7N4hTjBNy" target='blank'>
                                                      <FaDiscord className='w-auto h-7 text-white' />
                                                </a>
                                                <a href="https://linkedin.com/company/clysterum" target='blank'>
                                                      <FaLinkedin className='w-auto h-7 text-white' />
                                                </a>
                                          </div>
                                    </div>
                              </div>
                              <div className="hero-right flex-1 w-full md:h-3/5 h-auto justify-end flex mb-20">
                                    {/* <div className="right-box w-full h-full bg-white rounded-3xl shadow-soft md:px-8 md:py-8 py-8 px-4 flex-col flex justify-between md:gap-8 gap-8">
                                          <div className="top-part flex justify-between md:flex-row flex-col md:border-b-0 border-b md:pb-0 pb-4">
                                                <div className="top-left flex gap-3 md:flex-row flex-col md:items-start items-center pt-4">
                                                      <div className="coin-img">
                                                            <img src={ClysLogo} alt="coin" className='md:h-[90px] h-16 w-auto' />
                                                      </div>
                                                      <div className="coin-text flex flex-col h-full justify-center pb-4 md:text-start text-center">
                                                            <h5 className='text-gray-500'>Clysterum</h5>
                                                            <h4 className='font-black text-3xl text-clysblack'>CLY</h4>
                                                      </div>
                                                </div>
                                                <div className="top-right flex flex-col md:items-end items-start justify-center md:gap-1 gap-0">
                                                      <h5 className='text-gray-500'>Price</h5>
                                                      <h3 className='text-3xl font-black text-clysblack'>${clysterumData?.tokenPriceUSDT}</h3>
                                                      <h4 className='text-gray-400'>{tokenPriceInBNB.toFixed(8)} BNB</h4>
                                                </div>
                                          </div>
                                          <div className="middle-part flex flex-col gap-4 md:border-0 border-b md:pb-0 pb-4">
                                                <div className="middle-top flex justify-between md:flex-row flex-col md:gap-0 gap-4">
                                                      <div className="middle-top-left flex-col flex">
                                                            <h5 className='text-gray-500'>Round</h5>
                                                            <h3 className='text-2xl font-black text-clysblack w-2/3 uppercase'>{statusMessage}</h3>
                                                      </div>
                                                      <div className="middle-top-left flex-col flex">
                                                            <h5 className='text-gray-500'>Stage Name</h5>
                                                            <h3 className='text-2xl font-black text-clysblack w-full uppercase'>{stageMessage}</h3>
                                                      </div>
                                                      <div className="middle-top-right flex-col flex md:items-end items-start">
                                                            <h5 className='text-gray-500'>Time Left</h5>
                                                            <h3 className='text-xl font-black text-clysblack text-end'>
                                                                  {timeLeft}
                                                            </h3>
                                                      </div>
                                                </div>
                                                <div className="middle-bottom flex flex-col gap-1 md:mt-0 mt-2">
                                                      <div className="slider w-full bg-white rounded-3xl border-blue1 border-2 h-7 relative overflow-hidden">
                                                            <div className={`bg-blue1 slider-inside absolute h-full top-0 w-full rounded-3xl`} style={style}>

                                                            </div>
                                                            <h5 className='centered-text text-base'>Sale {salePercentage}%</h5>
                                                      </div>
                                                      <div className="sale-total flex gap-1 text-gray-500 text-sm w-full justify-end">
                                                            <h5 className='text-clysblack'>{clysterumData?.tokensSold?.toLocaleString()}</h5>
                                                            <h5 className='text-clysblack'>/</h5>
                                                            <h5 className='text-clysblack'>{clysterumData?.totalTokens.toLocaleString()}</h5>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="bottom-part border-b pb-4">
                                                <div className="bottom-top flex justify-between md:items-end items-start md:flex-row flex-col md:gap-0 gap-4">
                                                      <div className="left flex flex-col">
                                                            <h5 className='text-gray-500 md:text-base text-sm'>Token Distribution</h5>
                                                            <h3 className='text-2xl font-extrabold text-clysblack'>{clysterumData?.totalTokens.toLocaleString()}</h3>
                                                      </div>
                                                      <div className="right flex flex-col md:items-end items-start">
                                                            <h5 className='text-gray-500 md:text-base text-sm'>Total Raised</h5>
                                                            <h3 className='text-2xl font-extrabold text-clysblack'>${totalRaised?.toLocaleString()}/${clysterumData?.totalTargetUSDT?.toLocaleString()}</h3>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="sale-automate flex justify-between items-center -my-4 w-full">
                                                <a href="https://www.youtube.com/" target='blank'>
                                                      <h5 className='uppercase md:font-bold font-medium md:text-base text-xs'>How to buy?</h5>
                                                </a>
                                                <a href="/sales">
                                                      <button className='flex items-center gap-1 px-6 py-3 blue-bg text-white rounded-lg w-auto md:text-xs text-[10px]'>Buy Now <FaArrowRightLong /></button>
                                                </a>
                                          </div>
                                    </div> */}
                              </div>
                        </div>

                  </Container>
            </div>
      )
}

export default Hero
