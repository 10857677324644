
import React from 'react'
import Container from '../common/Container'

function ForPart() {
      return (
            <div className='for-part-container md:mt-20 mt-0 md:mb-0 mb-20 md:px-0 px-6'>
                  <Container>
                        <div className="for-part-inner-container flex md:gap-8 gap-16 md:flex-row flex-col">
                              <div className="investors flex flex-col flex-1">
                                    <h5 className='font-black blue-text text-2xl uppercase'>Join Safely</h5>
                                    <h3 className='font-black md:text-5xl text-4xl mt-2 text-clysblack uppercase'>Health Innovation</h3>
                                    <p className='mt-8'>Clysterum is advancing healthcare services with innovative technologies, focusing on lifelong health issues. It represents a true leap forward for the industry by introducing new financial principles in the health sector.</p>
                                    <p className='mt-4 font-bold'>Designed for investors.</p>
                              </div>
                              <div className="teams flex flex-col flex-1">
                                    <h5 className='font-black blue-text text-2xl uppercase'>For Health</h5>
                                    <h3 className='font-black md:text-5xl text-4xl mt-2 text-clysblack uppercase'>Healthy Future</h3>
                                    <p className='mt-8'>Clysterum offers a decentralized health platform shaped by a promising and innovative project in the healthcare sector. Now is the time to invest in your health and future. Join the Clysterum ecosystem and become a part of this innovative transformation in the healthcare industry.</p>
                                    <p className='font-bold mt-4'>Clysterum has created a strong momentum in the healthcare sector, focusing on the development of significant projects.</p>
                              </div>
                        </div>
                  </Container>
            </div>
      )
}

export default ForPart
