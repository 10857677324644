import React, { ReactNode, useEffect, useState } from 'react'
import { FaUserLarge } from "react-icons/fa6";
import BiggerContainer from '../common/BiggerContainer';
import { FaRegUser } from "react-icons/fa";
import { MdArrowDropDownCircle } from "react-icons/md";
import { FaSeedling } from "react-icons/fa";
import { BiTransferAlt } from "react-icons/bi";
import { MdLogout } from "react-icons/md";
import CoinLogo from '../../images/coinlogo.png';
import { FaArrowDown } from "react-icons/fa";
import Image1 from '../../images/profile/profile-top/1.png'
import Image2 from '../../images/profile/profile-top/2.png'
import Image3 from '../../images/profile/profile-top/3.png'
import Image4 from '../../images/profile/profile-top/4.png'
import { useAddress } from '@thirdweb-dev/react'
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

interface ProfileProps {
      children: ReactNode;
      number: number;
}

function ProfileMain({ children, number }: ProfileProps) {
      const address = useAddress();
      const walletAddress = address;
      const navigate = useNavigate();
      const { user, logout, isActive } = useAuth();

      // useEffect(() => {
      //       if (!user) {
      //             navigate('/');
      //       }
      // }, [user, navigate]);

      function kisaltAdres(adres: string, baslangicUzunlugu: number = 4, sonUzunlugu: number = 4): string {
            if (adres.length <= baslangicUzunlugu + sonUzunlugu) {
                  return adres;
            }
            return `${adres.substring(0, baslangicUzunlugu)}...${adres.substring(adres.length - sonUzunlugu)}`;
      }

      const kisaltılmısAdres = walletAddress ? kisaltAdres(walletAddress) : "Adres Yok";

      const links = [
            {
                  index: 0,
                  name: "Profile",
                  icon: <FaRegUser className='w-5 h-5' />,
                  link: "/profile",
            },
            {
                  index: 1,
                  name: "Airdrop",
                  icon: <MdArrowDropDownCircle className='w-5 h-5' />,
                  link: "/airdrop",
            },
            {
                  index: 2,
                  name: "Community Sale",
                  icon: <FaSeedling className='w-5 h-5' />,
                  link: "/sales",
            },
            {
                  index: 3,
                  name: "Transfer",
                  icon: <BiTransferAlt className='w-5 h-5' />,
                  link: "/profile/transfer",
            },
            {
                  index: 4,
                  name: "Logout",
                  icon: <MdLogout className='w-5 h-5' />,
                  link: "/",
            }
      ]

      const nonSortedLinks = links;
      const [sortedLinks, setSortedLinks] = useState(nonSortedLinks.sort((a, b) => (a.index === number ? -1 : b.index === number ? 1 : a.index - b.index)));
      const [linkCount, setLinkCount] = useState(1);
      const [slicedLinks, setSlicedLinks] = useState(sortedLinks.slice(0, linkCount));
      useEffect(() => {
            const newSortedLinks = nonSortedLinks.sort((a, b) =>
                  a.index === number ? -1 : b.index === number ? 1 : a.index - b.index
            );
            setSortedLinks(newSortedLinks);
            setSlicedLinks(newSortedLinks.slice(0, linkCount));
      }, []);

      useEffect(() => {
            setSlicedLinks(sortedLinks.slice(0, linkCount));
      }, [linkCount, sortedLinks]);

      const handleLinkList = () => {
            const newLinkCount = linkCount === 1 ? links.length : 1;
            setLinkCount(newLinkCount);
            setSlicedLinks(sortedLinks.slice(0, newLinkCount));
      };

      return (
            <div className='profile-main-container h-full w-full md:mb-[60px] mb-[40px] relative'>
                  <div className="background-profile absolute w-full h-[300px] top-0 left-0 -z-10"></div>
                  <BiggerContainer>
                        <div className="profile-main-inner-container flex flex-grow gap-4 md:pt-36 pt-12 mb-24 md:items-stretch md:flex-row flex-col-reverse md:px-0 px-8 items-center ">
                              <div className="profile-main-part flex-[8] w-full rounded-3xl">
                                    <div className="middle-part flex-col gap-6">
                                          <div className="top-part w-full h-auto shadow-soft rounded-3xl flex md:gap-0 gap-8 py-6 md:items-center p-[20px] mb-4 bg-white md:flex-row flex-col md:pl-4 pl-6">
                                                {user && (<>
                                                      <div className="part flex gap-4 flex-1">
                                                            <div className="coin-img">
                                                                  <img src={Image1} alt="coin" className='w-auto h-[64px]' />
                                                            </div>
                                                            <div className="coin-text flex">
                                                                  <div className="coin-title flex flex-col justify-between">
                                                                        <div className="title">
                                                                              <h5 className='text-2xl font-extrabold text-clysblack'>Address</h5>
                                                                        </div>
                                                                        <div className="bottom flex flex-col text-sm justify-center h-full">
                                                                              {walletAddress ? (
                                                                                    <h5>{kisaltılmısAdres}</h5>
                                                                              ) : (
                                                                                    <h5>Please Connect Wallet!</h5>
                                                                              )}
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className="part flex gap-4 flex-1">
                                                            <div className="coin-img">
                                                                  <img src={Image2} alt="coin" className='w-auto h-[64px]' />
                                                            </div>
                                                            <div className="coin-text flex">
                                                                  <div className="coin-title flex flex-col justify-between">
                                                                        <div className="title">
                                                                              <h5 className='text-2xl font-extrabold text-clysblack'>{user.totalCly}</h5>
                                                                        </div>
                                                                        <div className="bottom flex flex-col text-sm justify-center h-full">
                                                                              <h5>Airdrop Amount</h5>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className="part flex gap-4 flex-1">
                                                            <div className="coin-img">
                                                                  <img src={Image3} alt="coin" className='w-auto h-[64px]' />
                                                            </div>
                                                            <div className="coin-text flex">
                                                                  <div className="coin-title flex flex-col justify-between">
                                                                        <div className="title">
                                                                              <h5 className='text-2xl font-black text-clysblack'>{user.seedBalance}</h5>
                                                                        </div>
                                                                        <div className="bottom flex flex-col text-sm justify-center h-full">
                                                                              <h5>Community Sale Amount</h5>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      <div className="part flex gap-4 flex-1">
                                                            <div className="coin-img">
                                                                  <img src={Image4} alt="coin" className='w-auto h-[64px]' />
                                                            </div>
                                                            <div className="coin-text flex">
                                                                  <div className="coin-title flex flex-col justify-between">
                                                                        <div className="title">
                                                                              <h5 className='text-2xl font-black text-clysblack'>0 CP</h5>
                                                                        </div>
                                                                        <div className="bottom flex flex-col text-sm justify-center h-full">
                                                                              <h5>Clysterum Points</h5>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </>
                                                )}
                                          </div>
                                          {children}
                                    </div>
                              </div>
                              <div className="profile-navbar md:w-[300px] w-full md:h-[523px] h-auto rounded-3xl flex flex-col gap-0">
                                    <div className="profile-up flex flex-col items-center justify-center gap-4 w-full mt-[30px]">
                                          <div className="profile-up-container flex w-full flex-col items-center justify-center">
                                                <div className="profile-img p-4 rounded-full border-white border-2 w-20 h-20 flex items-center justify-center">
                                                      <FaUserLarge className='user-icon w-[36px] h-[36px]' color='white' />
                                                </div>
                                                <div className="profile-text text-white my-[28px]">
                                                      <h5 className='text-2xl font-bold'>ACCOUNT</h5>
                                                </div>
                                          </div>
                                    </div>
                                    <div className="profile-links flex flex-col w-full items-center h-full text-base text-white px-[36px] pb-[18px] pt-[10px] md:hidden">
                                          {slicedLinks.map(link => (
                                                <>
                                                      {link === slicedLinks[0] ?
                                                            <button className={`w-full hover:text-red-400 ${number === link.index ? 'text-red-400' : 'text-white'}`} onClick={handleLinkList} >
                                                                  <div className="link w-full flex gap-4 items-center justify-between">

                                                                        <div className="left flex gap-4">
                                                                              {link.icon}
                                                                              <h5 className='text-base'>{link.name}</h5>
                                                                        </div>
                                                                        <div className="right">
                                                                              <FaArrowDown className={`${linkCount === 1 ? "rotate-0" : "rotate-180"} dropdown-arrow`} />
                                                                        </div>
                                                                  </div>
                                                            </button> :
                                                            <a href={link.link} className={`w-full hover:text-red-400 ${number === link.index ? 'text-red-400' : 'text-white'}`} >
                                                                  {link.name === "Logout" ? (
                                                                        <div className="left flex gap-4">
                                                                              {link.icon}
                                                                              <h5 onClick={logout} className='text-base'>{link.name}</h5>
                                                                        </div>) : (
                                                                        <div className="link w-full flex gap-4 items-center">
                                                                              {link.icon}
                                                                              <h5 className='text-base'>{link.name}</h5>
                                                                        </div>
                                                                  )}
                                                            </a>
                                                      }
                                                      <div className="line h-[1px] w-full bg-blue4 my-[16px]"></div>
                                                      {slicedLinks.length === 1 ? <div className="pb-2"></div> : <></>}
                                                      {link === slicedLinks[4] ? <div className="pb-4"></div> : <></>}

                                                </>
                                          ))}
                                    </div>
                                    <div className="profile-links flex-col w-full items-center h-full text-base text-white px-[36px] pb-[18px] pt-[10px] md:flex hidden">
                                          {links.map(link => (
                                                <React.Fragment key={link.name}>
                                                      {link.name === "Logout" ? (
                                                            <button onClick={logout} className="w-full text-white hover:text-red-400">
                                                                  <div className="link w-full flex gap-4 items-center">
                                                                        {link.icon}
                                                                        <h5 className='text-base'>
                                                                              <a href="/">{link.name}</a>
                                                                        </h5>
                                                                  </div>
                                                            </button>
                                                      ) : (
                                                            <a href={link.link} className={`w-full hover:text-red-400 ${number === link.index ? 'text-red-400' : 'text-white'}`}>
                                                                  <div className="link w-full flex gap-4 items-center">
                                                                        {link.icon}
                                                                        <h5 className='text-base'>{link.name}</h5>
                                                                  </div>
                                                            </a>
                                                      )}
                                                      <div className="line h-[1px] w-full bg-blue4 my-[16px]"></div>
                                                </React.Fragment>
                                          ))}
                                    </div>
                              </div>
                        </div>
                  </BiggerContainer >
            </div >
      )
}

export default ProfileMain
