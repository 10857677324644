import React from 'react'
import { CiMail } from "react-icons/ci";
import { FaLongArrowAltRight } from "react-icons/fa";

function ProfileCommon() {
      return (
            <div className='profile-common w-full flex items-center flex-col mt-16 gap-12'>
                  <div className="texts text-center flex flex-col gap-1 w-full">
                        <h5 className='font-black text-4xl text-clysblack'>THE CLYSTERUM</h5>
                        <h5 className='font-black text-4xl text-blue1'>HEALTH FIRST</h5>
                  </div>
                  <div className="get-alerts flex flex-col py-8 md:px-16 px-5 rounded-3xl blue-bg gap-4 md:w-1/2 w-full">
                        <h2 className='font-black text-white md:text-xl text-[18px] text-center uppercase'>To be informed about developments</h2>
                        <div className="input-container w-full relative">
                              <CiMail className='w-6 h-auto absolute text-gray-400 top-2 left-2' />
                              <input type="text" placeholder='your email address..' className='w-full h-10 rounded-md pl-10 pr-16 outline-none text-gray-400 text-sm' />
                              <div className="arrow absolute right-1 top-[4px] bg-blue1 py-[6px] px-[16px] rounded-md cursor-pointer">
                                    <FaLongArrowAltRight className='w-5 h-auto text-white' />
                              </div>
                        </div>

                  </div>

            </div>
      )
}

export default ProfileCommon
