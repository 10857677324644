import React from 'react'
import Container from './Container'
import Logo1 from '../../images/logo1.png'
import Logo2 from '../../images/logo2.png'
import { CMC, GECKO } from '../../const/ContractAddresses';

function Footer() {
      return (
            <footer>
                  <div className="footer-container w-full h-auto">
                        <Container>
                              <div className="footer-inner-container h-full flex justify-between text-white md:px-0 px-6 flex-col md:text-base text-sm">
                                    <div className="up-part flex w-full md:justify-center md:gap-36 justify-between">
                                          <div className="social pt-10 flex flex-col gap-2">
                                                <div className="header">
                                                      <h5 className='font-black text-[18px]'>SOCIAL</h5>
                                                </div>
                                                <div className="links flex flex-col gap-2">
                                                      <a href="http://T.me/clysterum" target="_blank" className='font-normal'>Telegram</a>
                                                      <a href="http://X.com/clysterum" target="_blank" className='font-normal'>Twitter</a>
                                                      <a href="http://instagram.com/clysterum" target="_blank" className='font-normal'>Instagram</a>
                                                      <a href="http://Linkedin.com/company/clysterum" target="_blank" className='font-normal'>Linkedin</a>
                                                      <a href="https://youtube.com/@clysterumofficial?si=uagOIGp2OB72A0SO" target="_blank" className='font-normal'>Youtube</a>
                                                </div>
                                          </div>
                                          <div className="token pt-10 flex flex-col gap-2">
                                                <div className="header">
                                                      <h5 className='font-black text-[18px]'>TOKEN</h5>
                                                </div>
                                                <div className="links flex flex-col gap-2">
                                                      <a href={CMC} target="_blank" className='font-normal'>CoinGecko</a>
                                                      <a href={GECKO} target="_blank" className='font-normal'>CoinMarketCap</a>
                                                </div>
                                          </div>
                                          <div className="help pt-10 flex flex-col gap-2">
                                                <div className="header">
                                                      <h5 className='font-black text-[18px]'>HELP</h5>
                                                </div>
                                                <div className="links flex flex-col gap-2">
                                                      <a href="https://Clysterum.com/terms" target="_blank" className='font-normal'>Privacy Policy</a>
                                                      <a href="http://Clysterum.com/faqs" target="_blank" className='font-normal'>FAQ</a>
                                                      <a href="https://Clysterum.com/blogs" target="_blank" className='font-normal'>Blog</a>
                                                </div>
                                          </div>
                                    </div>
                                    <div className="logos flex gap-8 w-full items-center justify-center my-6">
                                          <div className="left-logo w-44 h-auto flex items-center justify-center">
                                                <a href="https://clysterum.com/" target='blank'>
                                                      <img src={Logo1} alt="logo1 w-full h-auto" />
                                                </a>
                                          </div>
                                          <div className="left-logo w-44 h-auto flex items-center justify-center px-4">
                                                <a href="https://chat.clysterum.com/" target='blank' >
                                                      <img src={Logo2} alt="logo1 w-full h-auto" />
                                                </a>
                                          </div>
                                    </div>
                                    <div className="copyright-text w-full flex items-center justify-center pb-4 text-center">
                                          <h5 className='md:text-base text-xs'>© 2023 Copyrights by Clysterum. All Rights Reserved.</h5>
                                    </div>
                              </div>
                        </Container>
                  </div>
            </footer>
      )
}

export default Footer
