import { useAddress, useConnect, metamaskWallet, useWalletConnect, ConnectWallet, walletConnect, trustWallet } from "@thirdweb-dev/react";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import Logo from "../../../images/coinlogo.png";
import MetaMask from "../../../images/MetamaskNormal.png";
import MetamaskBlue from '../../../images/metamaskblue.png'
import trustLogo from "../../../images/trustWallet.png";
import { IoClose } from "react-icons/io5";
import Alert from "../Alert";

const WalletConnectButton = () => {

   const [alertMessage, setalertMessage] = useState("");

   const metamaskConfig = metamaskWallet({
      projectId: "7fa837bc1e5effc88e18989c7474c9a8",
      connectionMethod: "walletConnect",
      recommended: true,
   });

   const connectWithMetamask = useConnect();
   const connectWithWalletConnect = useWalletConnect();
   const address = useAddress();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const { setWalletType, setWalletLogo } = useAuth();

   const checkMetamask = () => {
      return typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask;
   }

   const handleConnectMetaMask = async () => {
      if (checkMetamask()) {
         try {
            await connectWithMetamask(metamaskConfig);
            setWalletType("MetaMask");
            setWalletLogo(MetamaskBlue);
         } catch (error) {
            console.error('MetaMask bağlantısında bir hata oluştu:', error);
         }
      } else {
         setalertMessage('Please install MetaMask!');
      }
   };

   useEffect(() => {
      if (address) {
         setIsModalOpen(false);
      }
   }, [address]);

   const closeModal = (e: any) => {
      if (e.target.id === "modal-backdrop") {
         setIsModalOpen(false);
      }
   };

   return (
      <div className="text-center">
         <a className="font-bold text-white">
            <button
               onClick={() => setIsModalOpen(true)}
               className="connect px-8 py-3 rounded-lg border-2 text-white text-sm md:block"
            >
               CONNECT
            </button>
         </a>
         {isModalOpen && (
            <div
               id="modal-backdrop"
               onClick={closeModal}
               className="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center z-50"
            >
               <div className="bg-white rounded-2xl shadow-xl m-4 max-w-sm w-full relative p-6">
                  <div className="up-part flex justify-between w-full items-center h-auto">
                     <div className="logo-and-text flex gap-4 items-center">
                        <div className="logo h-full w-auto flex justify-center">
                           <img src={Logo} alt="logo" className="h-8 w-auto" />
                        </div>
                        <h3 className="text-xl font-semibold text-clysblack">
                           Connect
                        </h3>
                     </div>
                     <div className="close-button flex items-center h-full">
                        <button
                           onClick={() => setIsModalOpen(false)}
                           className=" text-clysblack hover:text-gray-800"
                        >
                           <IoClose className="font-bold w-6 h-6" />
                        </button>
                     </div>
                  </div>
                  <div className="buttons mb-4">
                     <div className="space-y-4 mt-6">
                        <button
                           onClick={async () => {
                              handleConnectMetaMask();
                              setWalletType("MetaMask");
                              setWalletLogo(MetamaskBlue);
                           }}
                           className="w-full flex items-center rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 gap-3"
                        >
                           <div className="img-container w-[56px] h-[56px] rounded-lg bg-orange-100 flex items-center justify-center">
                              <img
                                 src={MetaMask}
                                 alt="MetaMask"
                                 className="p-2 w-full h-auto"
                              />
                           </div>
                           <div className="texts flex flex-col gap-1 text-start">
                              <h4 className="text-clysblack font-semibold">
                                 MetaMask
                              </h4>
                              {/* <h5 className="text-gray-500 text-sm font-semibold">
                                 Installed
                              </h5> */}
                           </div>
                        </button>
                        <button
                           onClick={() => {
                              connectWithWalletConnect();
                              setWalletType("TrustWallet");
                              setWalletLogo(trustLogo);
                           }}
                           className="w-full flex items-center rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 gap-3"
                        >
                           <div className="img-container w-[56px] h-[56px] rounded-lg bg-[#3076bc] flex items-center justify-center">
                              <img
                                 src={trustLogo}
                                 alt="TrustWallet"
                                 className="p-[2px] w-full h-auto"
                              />
                           </div>
                           <div className="texts flex flex-col gap-1 text-start">
                              <h4 className="text-clysblack font-semibold">
                                 Trust Wallet
                              </h4>
                              {/* <h5 className="text-gray-500 text-sm font-semibold">
                                 Installed
                              </h5> */}
                           </div>
                        </button>
                     </div>
                  </div>
                  <div className="text border-t pt-4 w-full text-start">
                     <p className="text-sm text-clysblack">
                        Powered by Clysterum
                     </p>
                  </div>
               </div>
            </div>
         )}
         {alertMessage && <Alert message={alertMessage} onClose={() => setalertMessage('')} />}
      </div>
   );
};

export default WalletConnectButton;
