import React from 'react'
import VestingHeader from '../components/vesting/VestingHeader'
import VestingMain from '../components/vesting/VestingMain'

function Vesting() {
      return (
            <div>
                  <VestingHeader />
                  <VestingMain />
            </div>
      )
}

export default Vesting
