import React from 'react'
import AirdropHeader from '../components/airdrop/AirdropHeader'
import AirdropList from '../components/airdrop/AirdropList'

function Airdrop() {
      return (
            <div>
                  <AirdropHeader />
                  <AirdropList />
            </div>
      )
}

export default Airdrop
