import React, { ReactNode } from 'react';

interface Container {
      children: ReactNode;
}

function Container({ children }: Container) {
      return (
            <div className="container max-w-[1200px] h-full mx-auto">
                  {children}
            </div>
      );
}

export default Container;