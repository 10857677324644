export const tokenContract = "0x551F9fd824ef7B760137E3AD6982b678E823689D";
export const airdropContract = "0x819f48c0bf48896F3eC41323B56D7D3D2caaeaA7";
export const USDT = "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd";

export const recipientAddress = "0x14445FF2eAcBbCEA92972fd37Eab6b4f6545a5b3";

export const url = "https://app.clysterum.com";
export const GECKO = "";
export const CMC = "";

export const REACT_APP_TW_SECRET_KEY = "df6Iwr_3KGjtcqdOXOO0KudaG_dNmNHc9ToDu4Vc_Eli-4ANl0RDKpGvX9_XCZkqFzGGdGeopGzNoZe_2XAZEg";
export const REACT_APP_TEMPLATE_CLIENT_ID = "8a8f7b55d2a3444ec60a2a7d6d9098e6";

export const privateKey = "7e1b348dffba62fc3bc6148f4c5812eef93904d0d6a1a075168dd4b34d1f26b4";
export const claimContract = "0xE3CE52Af7Ea85F05E43c9125725175A96B9227b6";
export const claimContract2 = "0xc1F1f5447Fb6c30b3773C047cb040612e134B720";
export const claimAbi = [
    {
        "type": "constructor",
        "name": "",
        "inputs": [
            {
                "type": "address",
                "name": "_tokenAddress",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "event",
        "name": "AllowlistUpdated",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "indexed": true,
                "internalType": "address"
            },
            {
                "type": "bool",
                "name": "allowed",
                "indexed": false,
                "internalType": "bool"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "event",
        "name": "Claimed",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "indexed": true,
                "internalType": "address"
            },
            {
                "type": "uint256",
                "name": "amount",
                "indexed": false,
                "internalType": "uint256"
            },
            {
                "type": "string",
                "name": "transactionName",
                "indexed": false,
                "internalType": "string"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "event",
        "name": "OwnershipTransferred",
        "inputs": [
            {
                "type": "address",
                "name": "previousOwner",
                "indexed": true,
                "internalType": "address"
            },
            {
                "type": "address",
                "name": "newOwner",
                "indexed": true,
                "internalType": "address"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "event",
        "name": "TransactionRecorded",
        "inputs": [
            {
                "type": "uint256",
                "name": "date",
                "indexed": false,
                "internalType": "uint256"
            },
            {
                "type": "uint256",
                "name": "amount",
                "indexed": false,
                "internalType": "uint256"
            },
            {
                "type": "string",
                "name": "transactionName",
                "indexed": false,
                "internalType": "string"
            },
            {
                "type": "address",
                "name": "fromAddress",
                "indexed": false,
                "internalType": "address"
            },
            {
                "type": "address",
                "name": "toAddress",
                "indexed": false,
                "internalType": "address"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "function",
        "name": "addToAllowlist",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "allowlist",
        "inputs": [
            {
                "type": "address",
                "name": "",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "bool",
                "name": "",
                "internalType": "bool"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "claimCooldown",
        "inputs": [],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "getUserAmount",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "getUserClaimData",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "tuple",
                "name": "",
                "components": [
                    {
                        "type": "uint256",
                        "name": "amount",
                        "internalType": "uint256"
                    },
                    {
                        "type": "uint256",
                        "name": "claimCount",
                        "internalType": "uint256"
                    },
                    {
                        "type": "uint256",
                        "name": "lastClaimTime",
                        "internalType": "uint256"
                    }
                ],
                "internalType": "struct PreSeed.ClaimData"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "getUserTransactions",
        "inputs": [
            {
                "type": "address",
                "name": "_user",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "tuple[]",
                "name": "",
                "components": [
                    {
                        "type": "uint256",
                        "name": "date",
                        "internalType": "uint256"
                    },
                    {
                        "type": "uint256",
                        "name": "amount",
                        "internalType": "uint256"
                    },
                    {
                        "type": "string",
                        "name": "transactionName",
                        "internalType": "string"
                    },
                    {
                        "type": "address",
                        "name": "fromAddress",
                        "internalType": "address"
                    },
                    {
                        "type": "address",
                        "name": "toAddress",
                        "internalType": "address"
                    }
                ],
                "internalType": "struct PreSeed.TransactionRecord[]"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "increaseAmount",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            },
            {
                "type": "uint256",
                "name": "amount",
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "limitedClaim",
        "inputs": [
            {
                "type": "uint256",
                "name": "amount",
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "maxClaims",
        "inputs": [],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "owner",
        "inputs": [],
        "outputs": [
            {
                "type": "address",
                "name": "",
                "internalType": "address"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "remainingClaims",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "removeFromAllowlist",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "renounceOwnership",
        "inputs": [],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "setClaimCooldown",
        "inputs": [
            {
                "type": "uint256",
                "name": "newCooldown",
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "setMaxClaims",
        "inputs": [
            {
                "type": "uint256",
                "name": "newMaxClaims",
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "setToken",
        "inputs": [
            {
                "type": "address",
                "name": "_tokenAddress",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "token",
        "inputs": [],
        "outputs": [
            {
                "type": "address",
                "name": "",
                "internalType": "contract IERC20"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "transferOwnership",
        "inputs": [
            {
                "type": "address",
                "name": "newOwner",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "unlimitedClaim",
        "inputs": [
            {
                "type": "uint256",
                "name": "amount",
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    }
];

export const claimAbi2 = [
    {
        "type": "constructor",
        "name": "",
        "inputs": [
            {
                "type": "address",
                "name": "_tokenAddress",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "event",
        "name": "AllowlistUpdated",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "indexed": true,
                "internalType": "address"
            },
            {
                "type": "bool",
                "name": "allowed",
                "indexed": false,
                "internalType": "bool"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "event",
        "name": "Claimed",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "indexed": true,
                "internalType": "address"
            },
            {
                "type": "uint256",
                "name": "amount",
                "indexed": false,
                "internalType": "uint256"
            },
            {
                "type": "string",
                "name": "transactionName",
                "indexed": false,
                "internalType": "string"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "event",
        "name": "OwnershipTransferred",
        "inputs": [
            {
                "type": "address",
                "name": "previousOwner",
                "indexed": true,
                "internalType": "address"
            },
            {
                "type": "address",
                "name": "newOwner",
                "indexed": true,
                "internalType": "address"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "event",
        "name": "TransactionRecorded",
        "inputs": [
            {
                "type": "uint256",
                "name": "date",
                "indexed": false,
                "internalType": "uint256"
            },
            {
                "type": "uint256",
                "name": "amount",
                "indexed": false,
                "internalType": "uint256"
            },
            {
                "type": "string",
                "name": "transactionName",
                "indexed": false,
                "internalType": "string"
            },
            {
                "type": "address",
                "name": "fromAddress",
                "indexed": false,
                "internalType": "address"
            },
            {
                "type": "address",
                "name": "toAddress",
                "indexed": false,
                "internalType": "address"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "function",
        "name": "addToAllowlist",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "allowlist",
        "inputs": [
            {
                "type": "address",
                "name": "",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "bool",
                "name": "",
                "internalType": "bool"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "claimCooldown",
        "inputs": [],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "getUserAmount",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "getUserClaimData",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "tuple",
                "name": "",
                "components": [
                    {
                        "type": "uint256",
                        "name": "amount",
                        "internalType": "uint256"
                    },
                    {
                        "type": "uint256",
                        "name": "claimCount",
                        "internalType": "uint256"
                    },
                    {
                        "type": "uint256",
                        "name": "lastClaimTime",
                        "internalType": "uint256"
                    }
                ],
                "internalType": "struct SeedSale.ClaimData"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "getUserTransactions",
        "inputs": [
            {
                "type": "address",
                "name": "_user",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "tuple[]",
                "name": "",
                "components": [
                    {
                        "type": "uint256",
                        "name": "date",
                        "internalType": "uint256"
                    },
                    {
                        "type": "uint256",
                        "name": "amount",
                        "internalType": "uint256"
                    },
                    {
                        "type": "string",
                        "name": "transactionName",
                        "internalType": "string"
                    },
                    {
                        "type": "address",
                        "name": "fromAddress",
                        "internalType": "address"
                    },
                    {
                        "type": "address",
                        "name": "toAddress",
                        "internalType": "address"
                    }
                ],
                "internalType": "struct SeedSale.TransactionRecord[]"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "increaseAmount",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            },
            {
                "type": "uint256",
                "name": "amount",
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "limitedClaim",
        "inputs": [
            {
                "type": "uint256",
                "name": "amount",
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "maxClaims",
        "inputs": [],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "owner",
        "inputs": [],
        "outputs": [
            {
                "type": "address",
                "name": "",
                "internalType": "address"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "remainingClaims",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "removeFromAllowlist",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "renounceOwnership",
        "inputs": [],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "setClaimCooldown",
        "inputs": [
            {
                "type": "uint256",
                "name": "newCooldown",
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "setMaxClaims",
        "inputs": [
            {
                "type": "uint256",
                "name": "newMaxClaims",
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "setToken",
        "inputs": [
            {
                "type": "address",
                "name": "_tokenAddress",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "token",
        "inputs": [],
        "outputs": [
            {
                "type": "address",
                "name": "",
                "internalType": "contract IERC20"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "transferOwnership",
        "inputs": [
            {
                "type": "address",
                "name": "newOwner",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "unlimitedClaim",
        "inputs": [
            {
                "type": "uint256",
                "name": "amount",
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    }
];

export const tokenAbi = [
    {
        "type": "constructor",
        "name": "",
        "inputs": [
            {
                "type": "address",
                "name": "initialOwner",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "event",
        "name": "Approval",
        "inputs": [
            {
                "type": "address",
                "name": "owner",
                "indexed": true,
                "internalType": "address"
            },
            {
                "type": "address",
                "name": "spender",
                "indexed": true,
                "internalType": "address"
            },
            {
                "type": "uint256",
                "name": "value",
                "indexed": false,
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "event",
        "name": "OwnershipTransferred",
        "inputs": [
            {
                "type": "address",
                "name": "previousOwner",
                "indexed": true,
                "internalType": "address"
            },
            {
                "type": "address",
                "name": "newOwner",
                "indexed": true,
                "internalType": "address"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "event",
        "name": "Transfer",
        "inputs": [
            {
                "type": "address",
                "name": "from",
                "indexed": true,
                "internalType": "address"
            },
            {
                "type": "address",
                "name": "to",
                "indexed": true,
                "internalType": "address"
            },
            {
                "type": "uint256",
                "name": "value",
                "indexed": false,
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "function",
        "name": "DOMAIN_SEPARATOR",
        "inputs": [],
        "outputs": [
            {
                "type": "bytes32",
                "name": "",
                "internalType": "bytes32"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "allowance",
        "inputs": [
            {
                "type": "address",
                "name": "owner",
                "internalType": "address"
            },
            {
                "type": "address",
                "name": "spender",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "approve",
        "inputs": [
            {
                "type": "address",
                "name": "spender",
                "internalType": "address"
            },
            {
                "type": "uint256",
                "name": "amount",
                "internalType": "uint256"
            }
        ],
        "outputs": [
            {
                "type": "bool",
                "name": "",
                "internalType": "bool"
            }
        ],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "balanceOf",
        "inputs": [
            {
                "type": "address",
                "name": "account",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "decimals",
        "inputs": [],
        "outputs": [
            {
                "type": "uint8",
                "name": "",
                "internalType": "uint8"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "decreaseAllowance",
        "inputs": [
            {
                "type": "address",
                "name": "spender",
                "internalType": "address"
            },
            {
                "type": "uint256",
                "name": "subtractedValue",
                "internalType": "uint256"
            }
        ],
        "outputs": [
            {
                "type": "bool",
                "name": "",
                "internalType": "bool"
            }
        ],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "increaseAllowance",
        "inputs": [
            {
                "type": "address",
                "name": "spender",
                "internalType": "address"
            },
            {
                "type": "uint256",
                "name": "addedValue",
                "internalType": "uint256"
            }
        ],
        "outputs": [
            {
                "type": "bool",
                "name": "",
                "internalType": "bool"
            }
        ],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "mint",
        "inputs": [
            {
                "type": "address",
                "name": "to",
                "internalType": "address"
            },
            {
                "type": "uint256",
                "name": "amount",
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "name",
        "inputs": [],
        "outputs": [
            {
                "type": "string",
                "name": "",
                "internalType": "string"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "nonces",
        "inputs": [
            {
                "type": "address",
                "name": "owner",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "owner",
        "inputs": [],
        "outputs": [
            {
                "type": "address",
                "name": "",
                "internalType": "address"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "permit",
        "inputs": [
            {
                "type": "address",
                "name": "owner",
                "internalType": "address"
            },
            {
                "type": "address",
                "name": "spender",
                "internalType": "address"
            },
            {
                "type": "uint256",
                "name": "value",
                "internalType": "uint256"
            },
            {
                "type": "uint256",
                "name": "deadline",
                "internalType": "uint256"
            },
            {
                "type": "uint8",
                "name": "v",
                "internalType": "uint8"
            },
            {
                "type": "bytes32",
                "name": "r",
                "internalType": "bytes32"
            },
            {
                "type": "bytes32",
                "name": "s",
                "internalType": "bytes32"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "renounceOwnership",
        "inputs": [],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "symbol",
        "inputs": [],
        "outputs": [
            {
                "type": "string",
                "name": "",
                "internalType": "string"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "totalSupply",
        "inputs": [],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "transfer",
        "inputs": [
            {
                "type": "address",
                "name": "to",
                "internalType": "address"
            },
            {
                "type": "uint256",
                "name": "amount",
                "internalType": "uint256"
            }
        ],
        "outputs": [
            {
                "type": "bool",
                "name": "",
                "internalType": "bool"
            }
        ],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "transferFrom",
        "inputs": [
            {
                "type": "address",
                "name": "from",
                "internalType": "address"
            },
            {
                "type": "address",
                "name": "to",
                "internalType": "address"
            },
            {
                "type": "uint256",
                "name": "amount",
                "internalType": "uint256"
            }
        ],
        "outputs": [
            {
                "type": "bool",
                "name": "",
                "internalType": "bool"
            }
        ],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "transferOwnership",
        "inputs": [
            {
                "type": "address",
                "name": "newOwner",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    }
];

export const airdropAbi = [
    {
        "type": "constructor",
        "name": "",
        "inputs": [
            {
                "type": "address",
                "name": "_tokenAddress",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "event",
        "name": "AllowlistUpdated",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "indexed": true,
                "internalType": "address"
            },
            {
                "type": "bool",
                "name": "allowed",
                "indexed": false,
                "internalType": "bool"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "event",
        "name": "Claimed",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "indexed": true,
                "internalType": "address"
            },
            {
                "type": "uint256",
                "name": "amount",
                "indexed": false,
                "internalType": "uint256"
            },
            {
                "type": "string",
                "name": "transactionName",
                "indexed": false,
                "internalType": "string"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "event",
        "name": "OwnershipTransferred",
        "inputs": [
            {
                "type": "address",
                "name": "previousOwner",
                "indexed": true,
                "internalType": "address"
            },
            {
                "type": "address",
                "name": "newOwner",
                "indexed": true,
                "internalType": "address"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "event",
        "name": "TransactionRecorded",
        "inputs": [
            {
                "type": "uint256",
                "name": "date",
                "indexed": false,
                "internalType": "uint256"
            },
            {
                "type": "uint256",
                "name": "amount",
                "indexed": false,
                "internalType": "uint256"
            },
            {
                "type": "string",
                "name": "transactionName",
                "indexed": false,
                "internalType": "string"
            },
            {
                "type": "address",
                "name": "fromAddress",
                "indexed": false,
                "internalType": "address"
            },
            {
                "type": "address",
                "name": "toAddress",
                "indexed": false,
                "internalType": "address"
            }
        ],
        "outputs": [],
        "anonymous": false
    },
    {
        "type": "function",
        "name": "addToAllowlist",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "allowlist",
        "inputs": [
            {
                "type": "address",
                "name": "",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "bool",
                "name": "",
                "internalType": "bool"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "claimCooldown",
        "inputs": [],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "getUserTransactions",
        "inputs": [
            {
                "type": "address",
                "name": "_user",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "tuple[]",
                "name": "",
                "components": [
                    {
                        "type": "uint256",
                        "name": "date",
                        "internalType": "uint256"
                    },
                    {
                        "type": "uint256",
                        "name": "amount",
                        "internalType": "uint256"
                    },
                    {
                        "type": "string",
                        "name": "transactionName",
                        "internalType": "string"
                    },
                    {
                        "type": "address",
                        "name": "fromAddress",
                        "internalType": "address"
                    },
                    {
                        "type": "address",
                        "name": "toAddress",
                        "internalType": "address"
                    }
                ],
                "internalType": "struct Airdrop.TransactionRecord[]"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "limitedClaim",
        "inputs": [
            {
                "type": "uint256",
                "name": "amount",
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "maxClaims",
        "inputs": [],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "owner",
        "inputs": [],
        "outputs": [
            {
                "type": "address",
                "name": "",
                "internalType": "address"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "remainingClaims",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            }
        ],
        "outputs": [
            {
                "type": "uint256",
                "name": "",
                "internalType": "uint256"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "removeFromAllowlist",
        "inputs": [
            {
                "type": "address",
                "name": "user",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "renounceOwnership",
        "inputs": [],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "setClaimCooldown",
        "inputs": [
            {
                "type": "uint256",
                "name": "newCooldown",
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "setMaxClaims",
        "inputs": [
            {
                "type": "uint256",
                "name": "newMaxClaims",
                "internalType": "uint256"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "setToken",
        "inputs": [
            {
                "type": "address",
                "name": "_tokenAddress",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    },
    {
        "type": "function",
        "name": "token",
        "inputs": [],
        "outputs": [
            {
                "type": "address",
                "name": "",
                "internalType": "contract IERC20"
            }
        ],
        "stateMutability": "view"
    },
    {
        "type": "function",
        "name": "transferOwnership",
        "inputs": [
            {
                "type": "address",
                "name": "newOwner",
                "internalType": "address"
            }
        ],
        "outputs": [],
        "stateMutability": "nonpayable"
    }
];
