import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Layout from './Layout';
import ProfileProfile from './pages/ProfileDashboard/ProfileProfile';
import ProfileAirdrop from './pages/ProfileDashboard/ProfileAirdrop';
import ProfileTransfer from './pages/ProfileDashboard/ProfileTransfer';
import ProfileSeedSale from './pages/ProfileDashboard/ProfileSeedSale';
import Airdrop from './pages/Airdrop';
import Vesting from './pages/Vesting';
import Staking2 from './pages/Staking2';
import Login from './pages/Login';
import Verify from './pages/Verify';

function App() {
      return (
            <div className="App">
                  <Layout>
                        <Router>
                              <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/sales" element={<Staking2 />} />
                                    <Route path="/airdrop" element={<Airdrop />} />
                                    <Route path="/staking" element={<Staking2 />} />
                                    <Route path="/vesting" element={<Vesting />} />
                                    <Route path="/verify" element={<Verify />} />
                                    <Route path="/profile" element={<ProfileProfile />} />
                                    <Route path="/profile/airdrop" element={<ProfileAirdrop />} />
                                    <Route path="/profile/transfer" element={<ProfileTransfer />} />
                                    <Route path="/profile/seedsale" element={<ProfileSeedSale />} />
                              </Routes>
                        </Router>
                  </Layout>
            </div>
      );
}

export default App;
