import React, { useEffect, useState } from 'react';
import Container from '../common/Container';
import TokenImg from '../../images/coinlogo.png';
import { FaLock, FaCheck, FaExclamation } from "react-icons/fa";
import ProfileCommon from '../../pages/ProfileDashboard/ProfileCommon';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { ConnectWallet, useAddress, useContract, useContractRead, useContractWrite } from "@thirdweb-dev/react";
import { claimContract, claimContract2 } from '../../const/ContractAddresses';
import { ethers } from 'ethers';
import { FaArrowRightLong } from 'react-icons/fa6';
import WalletConnectButton from '../common/ConnectButton/WalletConnectButton';

interface StageData {
      endTime: string;
}
function VestingMain() {
      const address = useAddress();
      const { contract: contract1 } = useContract(claimContract);
      const { contract: contract2 } = useContract(claimContract2);
      const { data: readAmount1 } = useContractRead(contract1, "getUserAmount", [address]);
      const { data: readAmount2 } = useContractRead(contract2, "getUserAmount", [address]);
      const [formattedAmount1, setFormattedAmount1] = useState<number>(0);
      const [formattedAmount2, setFormattedAmount2] = useState<number>(0);
      const [vestingClaimStatus, setVestingClaimStatus] = useState<boolean[]>([]);
      const { isActive } = useAuth();
      const [stage1Data, setStage1Data] = useState<StageData | null>(null);
      const [stage2Data, setStage2Data] = useState<StageData | null>(null);
      const [activeStage, setActiveStage] = useState(1);
      const { mutateAsync: limitedClaim1, isLoading: claimLoading1 } = useContractWrite(contract1, "limitedClaim");
      const { mutateAsync: limitedClaim2, isLoading: claimLoading2 } = useContractWrite(contract2, "limitedClaim");

      useEffect(() => {
            if (readAmount1) {
                  const formattedAmount = ethers.utils.formatEther(readAmount1);
                  setFormattedAmount1(parseFloat(formattedAmount) / 7);
            }
            if (readAmount2) {
                  const formattedAmount = ethers.utils.formatEther(readAmount2);
                  setFormattedAmount2(parseFloat(formattedAmount) / 7);
            }
      }, [readAmount1, readAmount2]);


      useEffect(() => {
            const fetchStageData = async (stageId: number) => {
                  const response = await axios.get(`https://clysterum.com/api/token?stageId=${stageId}`);
                  return response.data.message as StageData;
            };
            fetchStageData(1).then(data => setStage1Data(data));
            fetchStageData(2).then(data => setStage2Data(data));
      }, []);

      useEffect(() => {
            const updateClaimStatus = (stageData: StageData, amount: number) => {
                  const endTime = new Date(stageData.endTime).getTime();
                  const cooldownPeriod = activeStage === 1 ? 45 * 24 * 60 * 60 * 1000 : 30 * 24 * 60 * 60 * 1000;
                  const firstClaimDate = new Date(endTime + cooldownPeriod);

                  const claimDates = new Array(7).fill(null).map((_, index) =>
                        new Date(firstClaimDate.getTime() + index * 30 * 24 * 60 * 60 * 1000)
                  );

                  const claimStatus = claimDates.map(date => new Date() >= date);
                  setVestingClaimStatus(claimStatus);
            };

            if (activeStage === 1 && stage1Data) {
                  updateClaimStatus(stage1Data, formattedAmount1);
            } else if (activeStage === 2 && stage2Data) {
                  updateClaimStatus(stage2Data, formattedAmount2);
            }
      }, [activeStage, stage1Data, stage2Data, formattedAmount1, formattedAmount2]);

      const handleClaim = async (vestingId: number) => {
            const contract = activeStage === 1 ? limitedClaim1 : limitedClaim2;
            const amount = activeStage === 1 ? readAmount1 / 7 : readAmount2 / 7;
            try {
                  const data = await contract({ args: [amount] });
                  console.info("contract call successs", data);
            } catch (error) {

            }
            console.log("Claim işlemi başarılı:", vestingId);
      };


      const getActiveVestingList = () => {
            const amount = activeStage === 1 ? formattedAmount1 : formattedAmount2;
            return new Array(7).fill(null).map((_, index) => ({
                  vestingID: index,
                  unlocked: 0.14285,
                  amount: amount || "No balance",
                  date: "10.01.2024",
                  isClaimable: vestingClaimStatus[index],
            }));
      };

      const activeVestingList = getActiveVestingList();

      const calculateCooldownEnd = () => {
            const data = activeStage === 1 ? stage1Data : stage2Data;
            if (!data) return '';

            const cooldownPeriod = activeStage === 1 ? 45 * 24 * 60 * 60 * 1000 : 30 * 24 * 60 * 60 * 1000;
            const formattedEndTime = data.endTime.replace(" ", "");
            const endTime = new Date(formattedEndTime).getTime();
            const cooldownEndTime = new Date(endTime + cooldownPeriod);

            return cooldownEndTime.toLocaleDateString();
      };

      const displayDateOrCountdown = (vesting: any, stageId: number) => {
            const now = new Date();
            const stageData = stageId === 1 ? stage1Data : stage2Data;
            if (!stageData) return "Data not available";

            const cooldownPeriod = stageId === 1 ? 44 * 24 * 60 * 60 * 1000 : 29 * 24 * 60 * 60 * 1000;
            const formattedEndTime = stageData.endTime.replace(" ", "");
            const endTime = new Date(formattedEndTime).getTime();
            const cooldownEndTime = new Date(endTime + cooldownPeriod);

            const additionalDelay = stageId === 1 && vesting.vestingID > 0 ? 30 * 24 * 60 * 60 * 1000 : 0;
            const claimDate = new Date(cooldownEndTime.getTime() + vesting.vestingID * additionalDelay);

            if (now >= claimDate) {
                  return "Claim available";
            } else if (vesting.vestingID === 0) {
                  const countdownDays = Math.ceil((claimDate.getTime() - now.getTime()) / (1000 * 3600 * 24));
                  return `${countdownDays} days remaining`;
            } else {
                  return "Waiting next claim";
            }
      };

      return (
            <div className='vesting-main-container -mt-20 mb-20 md:px-0 px-6'>
                  <Container>
                        {isActive ? (
                              <>
                                    {/* <div className="vesting-main-inner-container h-auto shadow-soft bg-white rounded-3xl w-full p-8 flex flex-col">
                                          <div className="token-info flex md:gap-3 gap-2 py-5 md:flex-row flex-col md:flex-[1] flex-[2] w-full justify-between">
                                                <div className="token-img ">
                                                      <div className="left flex flex-col md:items-center items-end">
                                                            <h5 className='md:text-3xl text-base font-extrabold text-clysblack'>Choose your joined Seed Sale Stage.</h5>
                                                      </div>
                                                </div>
                                                <div className="flex justify-center items-center gap-4">
                                                      <button
                                                            className={`px-6 py-3 rounded-lg font-semibold text-white transition-colors ${activeStage === 1 ? 'bg-blue-600' : 'bg-gray-300 hover:bg-gray-400'}`}
                                                            onClick={() => setActiveStage(1)}
                                                      >
                                                            Stage 1
                                                      </button>
                                                      <button
                                                            className={`px-6 py-3 rounded-lg font-semibold text-white transition-colors ${activeStage === 2 ? 'bg-blue-600' : 'bg-gray-300 hover:bg-gray-400'}`}
                                                            onClick={() => setActiveStage(2)}
                                                      >
                                                            Stage 2
                                                      </button>
                                                </div>
                                          </div>
                                    </div>
                                    <br /> */}
                              </>
                        ) : (
                              <>
                                    <div className="not-logged-in md:h-[400px] h-[250px] w-full flex items-center justify-center">
                                          <div className="flex flex-col gap-2 items-center">
                                                <div className="exclamation md:p-6 p-4 blue-bg rounded-3xl flex items-center justify-center mb-4">
                                                      <FaExclamation className="md:w-12 w-12 h-auto text-white" />
                                                </div>
                                                <h5 className="font-bold md:text-xl text-base text-clysblack text-center">
                                                      Unfortunately, you haven't logged in.
                                                </h5>
                                                <h5 className="font-bold md:text-xl text-base text-clysblack text-center">
                                                      You must log in to access this place!
                                                </h5>
                                          </div>
                                    </div>
                              </>
                        )}
                  </Container>
                  <Container>
                        {isActive && (
                              <div className="vesting-main-inner-container h-auto shadow-soft bg-white rounded-3xl w-full p-8 flex flex-col">
                                    <div className="vesting-main-header-container flex justify-between border-b pb-8">
                                          <div className="token-info flex md:gap-3 gap-2 md:flex-row flex-col md:flex-[1] flex-[2] w-full">
                                                <div className="token-img ">
                                                      <img src={TokenImg} alt="clystoken" className='md:h-16 md:w-auto h-auto w-16' />
                                                </div>
                                                <div className="token-text flex flex-col h-full justify-center">
                                                      <h5 className='md:text-3xl text-base font-extrabold text-clysblack'>CLYSTERUM</h5>
                                                      <div className="token-remaining flex gap-1 md:text-base text-sx">
                                                            <h5 className='font-semibold'>Claim Start Time: </h5>
                                                            <h5 className='text-blue1 font-semibold'>NULL</h5>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="price-value flex justify-end md:gap-12 gap-4 md:flex-row flex-col md:flex-[1] flex-[2]">
                                                <div className="left flex flex-col md:items-center items-end">
                                                      <h5 className='md:text-lg text-base font-semibold md:text-start text-end'>Token Price</h5>
                                                      <h5 className='font-extrabold blue-text md:text-2xl text-xl'>&lt; NULL</h5>
                                                </div>
                                                <div className="right flex flex-col md:items-center items-end">
                                                      <h5 className='md:text-lg text-base font-semibold md:text-start text-end'>Implied vesting value</h5>
                                                      <h5 className='font-extrabold blue-text md:text-2xl text-xl'>&lt; NULL</h5>
                                                </div>
                                          </div>
                                    </div>
                                    <div className="vesting-list-container flex flex-col pb-8">
                                          {activeVestingList.map((vesting, index) => {
                                                const dateDisplay = displayDateOrCountdown(vesting, activeStage);
                                                return (

                                                      <div key={vesting.vestingID} className="vesting-list-item py-8 border-b">
                                                            <div className='flex w-full h-full items-end justify-between md:flex-row flex-col md:gap-20 gap-4'>
                                                                  <div className="left flex justify-between w-full">
                                                                        <div className="unlocked flex md:gap-4 gap-1">
                                                                              <div className="unlock-icon mt-1">
                                                                                    {vesting.isClaimable ? <FaCheck color='blue' className='w-4 h-4' /> : <FaLock color='red' className='w-4 h-4' />}
                                                                              </div>
                                                                              <div className="unlock-texts flex-col">
                                                                                    <h5 className='md:text-lg text-sm text-clysblack'>Unlocked</h5>
                                                                                    <h5 className='font-bold md:text-xl text-base text-clysblack'>{"%" + vesting.unlocked * 100}</h5>
                                                                              </div>
                                                                        </div>
                                                                        <div className="amount flex flex-col">
                                                                              <h5 className='md:text-lg text-sm text-clysblack'>Amount</h5>
                                                                              <h5 className='font-bold md:text-xl text-base text-clysblack'>{vesting.amount}</h5>
                                                                        </div>
                                                                        <div className="date flex flex-col">
                                                                              <h5 className='md:text-lg text-sm text-clysblack'>Date</h5>
                                                                              <h5 className='font-bold md:text-xl text-base text-clysblack'>NULL</h5>
                                                                        </div>
                                                                  </div>
                                                                  <div className="right md:w-auto w-full h-full justify-end flex-col">
                                                                        <div className="button flex-[1] w-full justify-end h-full flex-col">
                                                                              {address ? (
                                                                                    <button
                                                                                          onClick={() => handleClaim(index)}
                                                                                          disabled={!vesting.isClaimable}
                                                                                          className={`flex items-center gap-2 px-6 py-3 rounded-lg w-full text-white ${vesting.isClaimable ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 text-white cursor-not-allowed'
                                                                                                }`}
                                                                                    >
                                                                                          Claim <FaArrowRightLong />
                                                                                    </button>) : (
                                                                                    <button disabled={false} className='flex items-center gap-2 px-6 py-3 bg-gray-400 text-white rounded-lg w-full'>Please, connect! <FaArrowRightLong /></button>
                                                                                    // <WalletConnectButton />

                                                                              )}
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                )
                                          })}
                                    </div>
                              </div>
                        )}
                  </Container>
                  <ProfileCommon />
            </div>
      );
}

export default VestingMain;

