import React from 'react'
import PageHeader from '../common/PageHeader'
import Container from '../common/Container'
import AirdropImg from '../../images/profile/profile-top/2.png'
import LockImg from '../../images/lock.png'
import { useAuth } from '../../context/AuthContext'

function AirdropHeader() {
      const { user } = useAuth();
      
      return (
            <div className='airdrop-header-container w-full h-auto pt-16 pb-32'>
                  <div className="background-airdrop absolute w-full h-[700px] top-0 left-0 -z-10"></div>
                  <Container>
                        <div className="airdrop-header-inner-container flex w-full justify-center items-center flex-col gap-20 md:px-0 px-6">
                              <div className="title text-center text-white">
                                    <h1 className='md:text-6xl text-4xl font-black'>AIRDROP</h1>
                                    <h1 className='md:text-6xl text-4xl font-black'>DASHBOARD</h1>
                              </div>
                              <div className="vesting-summary flex md:w-3/4 w-full md:px-0 md:h-40 h-auto bg-white rounded-3xl relative flex-col items-center">
                                    <div className="vesting-summary-title text-white blue-bg absolute -top-7 rounded-xl md:px-12 py-3 border-white border-2 left-1/2 -translate-x-1/2 flex flex-col md:w-auto w-3/4">
                                          <h5 className='font-bold md:text-lg text-base text-center'>YOUR AIRDROP SUMMARY</h5>
                                    </div>
                                    <div className="bottom-part flex md:items-center items-start h-full md:pt-4 pt-12 md:pb-0 pb-8 justify-start md:gap-20 gap-6 md:flex-row flex-col">
                                          <div className="left-part-1 flex items-center gap-4">
                                                <div className="token-img ">
                                                      <img src={AirdropImg} alt="clystoken" className='h-20 w-auto' />
                                                </div>
                                                <div className="text flex flex-col h-full justify-center">
                                                      <h5 className="title font-bold text-lg text-clysblack">Airdrop Amount</h5>
                                                      <h5 className="amount text-3xl font-extrabold text-clysblack">{user?.totalCly}</h5>
                                                </div>
                                          </div>
                                          <div className="right-part-1 flex items-center gap-4">
                                                <div className="token-img ">
                                                      <img src={LockImg} alt="clystoken" className='h-20 w-auto' />
                                                </div>
                                                <div className="text flex flex-col h-full justify-center">
                                                      <h5 className="title font-bold text-lg text-clysblack">Total Locked Token</h5>
                                                      <h5 className="amount text-3xl font-extrabold text-clysblack">{user?.totalCly}</h5>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </Container>
            </div>
      )
}

export default AirdropHeader
