import React, { ReactNode } from 'react';
import Header from './components/common/Header';
import Footer from './components/common/Footer';

interface LayoutProps {
      children: ReactNode;
}

function Layout({ children }: LayoutProps) {
      return (
            <div>
                  <Header />
                  <main>{children}</main>
                  <Footer />
            </div>
      );
}

export default Layout;