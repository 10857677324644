import React, { useState, useEffect } from 'react'
import Container from '../common/Container'
import TokenImg from '../../images/coinlogo.png'
import { FaArrowRightLong, FaLock, FaCheck, FaExclamation } from 'react-icons/fa6'
import ProfileCommon from '../../pages/ProfileDashboard/ProfileCommon'
import ClysLogo from '../../images/clysterumlogo.png';
import { useAuth } from '../../context/AuthContext'
import { ConnectWallet, useAddress, useContract, useContractRead, useContractWrite } from '@thirdweb-dev/react'
import { ethers } from 'ethers'
import { airdropAbi, airdropContract, privateKey } from '../../const/ContractAddresses'
import axios from 'axios';
import WalletConnectButton from '../common/ConnectButton/WalletConnectButton'

interface VestingEntry {
      vestingID: number;
      unlocked: number;
      amount: number;
      date: string;
      isClaimable: boolean;
}

function AirdropList() {
      const { user, decreaseTotalCly, isActive } = useAuth();
      const address = useAddress();
      const { contract: airdropContracts } = useContract(airdropContract);
      const { mutateAsync: claim } = useContractWrite(airdropContracts, "limitedClaim")
      const { data: allowList, isLoading: allowListLoading } = useContractRead(airdropContracts, "allowlist", [address]);

      const totalCly: any = user?.totalCly;
      const perClaimCly: any = totalCly / 11;

      const provider = new ethers.providers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545/');
      const wallet = new ethers.Wallet(privateKey, provider);
      const contracts = new ethers.Contract(airdropContract, airdropAbi, wallet)

      const [vestingList, setVestingList] = useState<VestingEntry[]>([]);
      const [claimedIDs, setClaimedIDs] = useState<number[]>([]);
      const [startTime, setStartTime] = useState<Date | null>(null);

      const fetchStartTime = async () => {
            try {
                  const response = await axios.get('https://clysterum.com/api/airdrop/');
                  const apiStartTime = response.data.message.startTime;
                  setStartTime(new Date(apiStartTime));
            } catch (error) {
                  console.error('Error fetching start time', error);
                  setStartTime(null);
            }
      };

      useEffect(() => {
            fetchStartTime();
      }, []);

      const formattedStartTime = startTime?.toLocaleString();

      useEffect(() => {
            if (startTime) {
                  const now = new Date();
                  const vestingPeriod = 30 * 24 * 60 * 60 * 1000;
                  const vestingEntries = Array.from({ length: 11 }, (_, index) => {
                        const claimDate = new Date(startTime.getTime() + index * vestingPeriod);
                        return {
                              vestingID: index,
                              unlocked: 0.0909,
                              amount: user && user.totalCly ? user.totalCly / 11 : 0,
                              date: claimDate.toLocaleDateString(),
                              isClaimable: now >= claimDate,
                        };
                  });
                  setVestingList(vestingEntries);
            } else {
                  console.log("date is a null!");
                  const vestingEntries = Array.from({ length: 11 }, (_, index) => {
                        return {
                              vestingID: index,
                              unlocked: 0.0909,
                              amount: user && user.totalCly ? user.totalCly / 11 : 0,
                              date: "NULL",
                              isClaimable: false,
                        };
                  });
                  setVestingList(vestingEntries);
            }
      }, [startTime, user?.totalCly]);

      const allowToUser = async () => {
            const totalClyWei = ethers.utils.parseUnits(totalCly.toString(), 'ether');
            try {
                  const data = await contracts.functions?.addToAllowlist(address);
                  const data2 = await contracts.functions?.increaseAmount(address, totalClyWei);
                  console.info('User added!', data, data2);
            } catch (error) {
                  console.log('User not added!', error);
            }
      }

      useEffect(() => {
            if (address && !allowListLoading && allowList === false) {
                  allowToUser();
            }
      }, [address, allowList, allowListLoading]);

      const handleClaimToken = async (vesting: VestingEntry) => {
            const claimAmount = vesting.amount;
            const claimAmountInWei = ethers.utils.parseUnits(claimAmount.toString(), 'ether');
            if (vesting.isClaimable && !claimedIDs.includes(vesting.vestingID)) {
                  try {
                        const Claim = await claim({ args: [claimAmountInWei] });
                        console.info("contract call success", Claim);
                        const remainingAmount = claimAmount - vesting.amount;
                        await decreaseTotalCly(remainingAmount);
                        setClaimedIDs([...claimedIDs, vesting.vestingID]);
                  } catch (error) {
                        console.info("Claim failure!", error)
                  }
            }
      };

      return (
            <div className='vesting-main-container -mt-20 mb-20 md:px-0 px-6'>
                  <Container>
                        {isActive ? (
                              <div className="vesting-main-inner-container h-auto shadow-soft bg-white rounded-3xl w-full p-8 flex flex-col">
                                    <div className="vesting-main-header-container flex justify-between border-b pb-8">
                                          <div className="token-info flex md:gap-3 gap-2 md:flex-row flex-col md:flex-[1] flex-[2] w-full">
                                                <div className="token-img ">
                                                      <img src={TokenImg} alt="clystoken" className='md:h-[56PX] md:w-auto h-auto w-16' />
                                                </div>
                                                <div className="token-text flex flex-col h-full justify-center">
                                                      <h5 className='md:text-2xl text-base font-extrabold text-clysblack'>CLYSTERUM</h5>
                                                      <div className="token-remaining flex gap-1 md:text-base text-sx">
                                                            <h5 className='font-semibold'>Claim Start Time: </h5>
                                                            <h5 className='text-blue1 font-semibold'>{formattedStartTime}</h5>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className="price-value flex justify-end md:gap-12 gap-4 md:flex-row flex-col md:flex-[1] flex-[2]">
                                                <div className="left flex flex-col md:items-center items-end">
                                                      <h5 className='md:text-lg text-base font-semibold md:text-start text-end'>Token Price</h5>
                                                      <h5 className='font-extrabold blue-text md:text-2xl text-xl'>&lt; NULL</h5>
                                                </div>
                                                <div className="right flex flex-col md:items-center items-end">
                                                      <h5 className='md:text-lg text-base font-semibold md:text-start text-end'>Implied vesting value</h5>
                                                      <h5 className='font-extrabold blue-text md:text-2xl text-xl'>&lt; NULL</h5>
                                                </div>
                                          </div>
                                    </div>
                                    <div className="vesting-list-container flex flex-col pb-8">

                                          {vestingList.map((vesting) => (
                                                <div key={vesting.vestingID} className="vesting-list-item py-8 border-b">
                                                      {vesting.isClaimable ?
                                                            <div className='flex w-full h-full items-end justify-between md:flex-row flex-col md:gap-20 gap-4 md:border-b-0 border-b'>
                                                                  <div className="left flex justify-between w-full">
                                                                        <div className="unlocked flex md:gap-4 gap-1">
                                                                              <div className="unlock-icon mt-1"><FaCheck color='blue' className='w-4 h-4' /></div>
                                                                              <div className="unlock-texts flex-col">
                                                                                    <h5 className='md:text-lg text-sm text-clysblack'>Unlocked</h5>
                                                                                    <h5 className='font-bold md:text-xl text-base text-clysblack'>{"%" + vesting.unlocked * 100}</h5>
                                                                              </div>
                                                                        </div>
                                                                        <div className="amount flex flex-col">
                                                                              <h5 className='md:text-lg text-sm text-clysblack'>Amount</h5>
                                                                              <h5 className='font-bold md:text-xl text-base text-clysblack'>{vesting.amount.toFixed(4)}</h5>
                                                                        </div>
                                                                        <div className="date flex flex-col">
                                                                              <h5 className='md:text-lg text-sm text-clysblack'>Date</h5>
                                                                              <h5 className='font-bold md:text-xl text-base text-clysblack'>{vesting.date}</h5>
                                                                        </div>
                                                                  </div>
                                                                  <div className="right md:w-auto w-full h-full justify-end flex-col">
                                                                        {address ? (
                                                                              <div className="button flex-[1] w-full justify-end h-full flex-col">
                                                                                    <button className='flex items-center gap-2 px-6 py-3 blue-bg text-white rounded-lg w-full'>Claim <FaArrowRightLong /></button>
                                                                              </div>
                                                                        ) : (
                                                                              <div className="right md:w-auto w-full">
                                                                                    <div className="button flex-[1] w-full">
                                                                                          <button disabled={!vesting.isClaimable || claimedIDs.includes(vesting.vestingID)} className='flex items-center gap-2 px-6 py-3 bg-gray-400 text-white rounded-lg w-full'>Please, connect! <FaArrowRightLong /></button>
                                                                                    </div>
                                                                              </div>
                                                                              //<WalletConnectButton />
                                                                        )}
                                                                  </div>

                                                            </div>
                                                            :
                                                            <div key={vesting.vestingID} className='flex w-full h-full items-end justify-between md:flex-row flex-col md:gap-20 gap-4 md:border-b-0 border-b'>
                                                                  <div className="left flex justify-between w-full">
                                                                        <div className="unlocked flex md:gap-4 gap-1">
                                                                              <div className="unlock-icon mt-1"><FaLock color='red' className='w-4 h-4' /></div>
                                                                              <div className="unlock-texts flex-col">
                                                                                    <h5 className='md:text-lg text-sm text-clysblack'>Unlocked</h5>
                                                                                    <h5 className='font-bold md:text-xl text-base text-clysblack'>{"%" + vesting.unlocked * 100}</h5>
                                                                              </div>
                                                                        </div>
                                                                        <div className="amount flex flex-col">
                                                                              <h5 className='md:text-lg text-sm text-clysblack'>Amount</h5>
                                                                              <h5 className='font-bold md:text-xl text-base text-clysblack'>{perClaimCly.toFixed(2)}</h5>
                                                                        </div>
                                                                        <div className="date flex flex-col">
                                                                              <h5 className='md:text-lg text-sm text-clysblack'>Date</h5>
                                                                              <h5 className='font-bold md:text-xl text-base text-clysblack'>{vesting.date}</h5>
                                                                        </div>
                                                                  </div>
                                                                  {address ? (
                                                                        <div className="right md:w-auto w-full">
                                                                              <div className="button flex-[1] w-full">
                                                                                    <button disabled={!vesting.isClaimable || claimedIDs.includes(vesting.vestingID)} onClick={() => handleClaimToken(vesting)} className='flex items-center gap-2 px-6 py-3 bg-gray-400 text-white rounded-lg w-full'>Claim <FaArrowRightLong /></button>
                                                                              </div>
                                                                        </div>
                                                                  ) : (
                                                                        <div className="right md:w-auto w-full">
                                                                              <div className="button flex-[1] w-full">
                                                                                    <button disabled={!vesting.isClaimable || claimedIDs.includes(vesting.vestingID)} className='flex items-center gap-2 px-6 py-3 bg-gray-400 text-white rounded-lg w-full'>Please, connect! <FaArrowRightLong /></button>
                                                                              </div>
                                                                        </div>
                                                                        //<WalletConnectButton />
                                                                  )}

                                                            </div>

                                                      }

                                                </div>
                                          ))}
                                    </div>
                              </div>
                        ) : (
                              <>
                                    <div className="not-logged-in md:h-[400px] h-[250px] w-full flex items-center justify-center">
                                          <div className="flex flex-col gap-2 items-center">
                                                <div className="exclamation md:p-6 p-4 blue-bg rounded-3xl flex items-center justify-center mb-4">
                                                      <FaExclamation className="md:w-12 w-12 h-auto text-white" />
                                                </div>
                                                <h5 className="font-bold md:text-xl text-base text-clysblack text-center">
                                                      Unfortunately, you haven't logged in.
                                                </h5>
                                                <h5 className="font-bold md:text-xl text-base text-clysblack text-center">
                                                      You must log in to access this place!
                                                </h5>
                                          </div>
                                    </div>
                              </>
                        )}
                        <ProfileCommon />
                  </Container>
            </div>
      )
}

export default AirdropList
