import React, { Ref, useEffect, useRef, useState } from "react";
import Container from "../common/Container";
import Banner from "../../images/banner.jpg";
import Banner2 from "../../images/banner2.jpg";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import { tokenContract, claimContract, claimContract2 } from "../../const/ContractAddresses";

interface StageData {
    startTime: string;
    endTime: string;
}

function SalesPart() {
    const { clysterumData } = useAuth();

    const [pageIndex, setPageIndex] = useState(0);

    const handlePageIndex = (index: number) => {
        setPageIndex(index);
    };

    const [stage1Data, setStage1Data] = useState<StageData | null>(null);
    const [stage2Data, setStage2Data] = useState<StageData | null>(null);
    const [activeContractAddress, setActiveContractAddress] = useState("");
    const [activePercent, setactivePercent] = useState("")


    useEffect(() => {
        const fetchStageData = async (stageId: number) => {
            const response = await axios.get(`https://clysterum.com/api/token?stageId=${stageId}`);
            return response.data.message;
        };

        fetchStageData(1).then(setStage1Data);
        fetchStageData(2).then(setStage2Data);
    }, []);

    const getActiveStage = () => {
        const now = new Date().getTime();

        const isActiveStage1 = stage1Data && now >= new Date(stage1Data.startTime.replace(/ /g, "")).getTime() && now <= new Date(stage1Data.endTime.replace(/ /g, "")).getTime();
        const isActiveStage2 = stage2Data && now >= new Date(stage2Data.startTime.replace(/ /g, "")).getTime() && now <= new Date(stage2Data.endTime.replace(/ /g, "")).getTime();

        return isActiveStage1 ? stage1Data : (isActiveStage2 ? stage2Data : null);
    };

    const activeStage = getActiveStage();

    useEffect(() => {
        let activeContract: any;
        let activePercent: any;

        if (activeStage === stage1Data) {
            activeContract = claimContract;
            activePercent = "13.5% - 7.20% 45 days cliff, Month linear vesting";
        } else if (activeStage === stage2Data) {
            activeContract = claimContract2;
            activePercent = "17.5% - 8.25% 1 month cliff, Month linear vesting";
        }

        setActiveContractAddress(activeContract);
        setactivePercent(activePercent);
    }, [stage1Data, stage2Data]);

    return (
        <div className="sales-part-container mb-20">
            <Container>
                <div className="sales-part-inner-container md:w-4/5 h-auto w-11/12 mx-auto">
                    <div className="top-part w-full flex justify-between gap-1 bg-transparent">
                        <div
                            className={`link-part rounded-t-2xl flex-1 w-full h-auto flex items-center justify-center py-3 cursor-pointer ${pageIndex === 0
                                ? "bg-white shadow-soft2"
                                : "bg-gray-300 "
                                }`}
                            onClick={() => handlePageIndex(0)}
                        >
                            <h5 className="md:text-base text-xs font-bold text-clysblack">
                                Sale Info
                            </h5>
                        </div>
                        <div
                            className={`link-part rounded-t-2xl flex-1 w-full h-auto flex items-center justify-center py-3 cursor-pointer ${pageIndex === 1
                                ? "bg-white shadow-soft2"
                                : "bg-gray-300 "
                                }`}
                            onClick={() => handlePageIndex(1)}
                        >
                            <h5 className="md:text-base text-xs font-bold text-clysblack">
                                Token Info
                            </h5>
                        </div>
                        <div
                            className={`link-part rounded-t-2xl flex-1 w-full h-auto flex items-center justify-center py-3 cursor-pointer ${pageIndex === 2
                                ? "bg-white shadow-soft2"
                                : "bg-gray-300 "
                                }`}
                            onClick={() => handlePageIndex(2)}
                        >
                            <h5 className="md:text-base text-xs font-bold text-clysblack">
                                About the Project
                            </h5>
                        </div>
                    </div>
                    <div className="inside-part w-full h-auto rounded-b-3xl shadow-soft3">
                        {pageIndex === 0 && (
                            <div className="sale-info flex flex-col py-4 px-6 md:text-base text-sm">
                                <div className="row flex justify-between py-3 md:flex-row flex-col md:items-start items-center">
                                    <h5 className="font-normal text-gray-500">
                                        Project Website
                                    </h5>
                                    <h5 className="font-bold text-clysblack">
                                        <a href="https://clysterum.com" className="font-bold text-clysblack" style={{ textDecoration: "none" }}>clysterum.com</a>
                                    </h5>
                                </div>
                                <div className="line h-[1px] w-full bg-gray-300 bg-opacity-50"></div>
                                <div className="row flex justify-between py-3 md:flex-row flex-col md:items-start items-center">
                                    <h5 className="font-normal text-gray-500">
                                        Total Number of Users
                                    </h5>
                                    <h5 className="font-bold text-clysblack">30.000+</h5>
                                </div>
                                <div className="line h-[1px] w-full bg-gray-300 bg-opacity-50"></div>
                                <div className="row flex justify-between py-3 md:flex-row flex-col md:items-start items-center">
                                    <h5 className="font-normal text-gray-500">
                                        Vesting
                                    </h5>
                                    <h5 className="font-bold text-clysblack">
                                        --
                                    </h5>
                                </div>
                                {/* <div className="line h-[1px] w-full bg-gray-300 bg-opacity-50"></div>
                        <div className="row flex justify-between py-3 md:flex-row flex-col md:items-start items-center">
                           <h5 className="font-normal text-gray-500">TGE</h5>
                           <h5 className="font-bold text-clysblack">
                           7.20% 45 days cliff, Month linear vesting
                           </h5>
                        </div> */}
                                <div className="line h-[1px] w-full bg-gray-300 bg-opacity-50"></div>
                                <div className="row flex justify-between py-3 md:flex-row flex-col md:items-start items-center">
                                    <h5 className="font-normal text-gray-500">
                                        Sale Contract Address
                                    </h5>
                                    <h5 className="font-bold text-clysblack">--</h5>
                                </div>
                            </div>
                        )}
                        {pageIndex === 1 && (
                            <div className="token-info flex flex-col py-4 px-6 md:text-base text-sm">
                                <div className="row flex justify-between py-3 md:flex-row flex-col md:items-start items-center">
                                    <h5 className="font-normal text-gray-500">
                                        Token Name
                                    </h5>
                                    <h5 className="font-bold text-clysblack">
                                        Clysterum
                                    </h5>
                                </div>
                                <div className="line h-[1px] w-full bg-gray-300 bg-opacity-50"></div>
                                <div className="row flex justify-between py-3 md:flex-row flex-col md:items-start items-center">
                                    <h5 className="font-normal text-gray-500">
                                        Token Symbol
                                    </h5>
                                    <h5 className="font-bold text-clysblack">CLY</h5>
                                </div>
                                <div className="line h-[1px] w-full bg-gray-300 bg-opacity-50"></div>
                                <div className="row flex justify-between py-3 md:flex-row flex-col md:items-start items-center">
                                    <h5 className="font-normal text-gray-500">
                                        Token Decimals
                                    </h5>
                                    <h5 className="font-bold text-clysblack">18</h5>
                                </div>
                                <div className="line h-[1px] w-full bg-gray-300 bg-opacity-50"></div>
                                <div className="row flex justify-between py-3 md:flex-row flex-col md:items-start items-center">
                                    <h5 className="font-normal text-gray-500">
                                        Total Supply
                                    </h5>
                                    <h5 className="font-bold text-clysblack">
                                        3.000.000.000
                                    </h5>
                                </div>
                                <div className="line h-[1px] w-full bg-gray-300 bg-opacity-50"></div>
                                <div className="row flex justify-between py-3 md:flex-row flex-col md:items-start items-center">
                                    <h5 className="font-normal text-gray-500">
                                        Token Address
                                    </h5>
                                    <h5 className="font-bold text-clysblack">{tokenContract}</h5>
                                </div>
                            </div>
                        )}
                        {pageIndex === 2 && (
                            <div className="about-the-project flex pt-4 pb-10 pl-6 pr-6 md:text-base text-sm md:gap-6 gap-6 md:flex-row flex-col">
                                <div className="table-of-contents flex-[3] flex flex-col gap-4 w-full">
                                    <h5 className="uppercase font-bold">
                                        Table of Contents
                                    </h5>
                                    <div className="links flex flex-col gap-2">
                                        <a href="#first" className="font-medium text-sm">
                                            1. Summary
                                        </a>
                                        <a href="#second" className="font-medium text-sm">
                                            2. Main Features
                                        </a>
                                        <a href="#third" className="font-medium text-sm">
                                            3. Artifical Intelligence in Healthcare
                                        </a>
                                        <a href="#fourth" className="font-medium text-sm">
                                            4. Tokenomics
                                        </a>
                                        <a href="#fifth" className="font-medium text-sm">
                                            5. Vesting for TGE
                                        </a>
                                    </div>
                                </div>
                                <div className="table-inside flex-[7] max-h-[600px] overflow-hidden overflow-y-auto custom-scroll pr-6">
                                    <div className="img-container w-full h-auto ">
                                        <img
                                            src={Banner2}
                                            alt="banner"
                                            className="w-full h-auto"
                                        />
                                    </div>
                                    <div
                                        className="first"
                                        id="first"
                                        style={{ scrollMarginTop: 100 }}
                                    >
                                        <h5 className="uppercase font-bold mt-8 text-xl">
                                            Summary
                                        </h5>
                                        <p className="mt-4 text-gray-500 font-medium text-[14px]">
                                            Clysterum is an innovative healthcare project
                                            established to address critical challenges in
                                            the healthcare sector by integrating Artificial
                                            Intelligence (AI), blockchain, and the Internet
                                            of Things (IoT). It aims to transform the
                                            delivery of healthcare services, prioritizing
                                            patient care, data security, and operational
                                            efficiency.
                                        </p>
                                        <p className="mt-4 text-gray-500 font-medium text-[14px]">
                                            In emergencies, Clysterum's mobile application
                                            becomes a vital tool that allows users to
                                            seamlessly communicate urgent blood requests.
                                            The application goes beyond being a healthcare
                                            tool, acting as a friendly companion that
                                            provides medication reminders, locates the
                                            nearest pharmacy, and directs to the closest
                                            hospital, ensuring essential healthcare needs
                                            are just a touch away.
                                        </p>
                                    </div>
                                    <div
                                        className="second"
                                        id="second"
                                        style={{ scrollMarginTop: 100 }}
                                    >
                                        <h5 className="uppercase font-bold mt-8 text-lg">
                                            Main Futures
                                        </h5>
                                        <p className="mt-4 text-gray-500 font-medium text-[14px]">
                                            At the core of Clysterum's capabilities is an
                                            AI-powered health chat panel. This innovative
                                            feature stands ready to answer questions and
                                            provide guidance as a knowledgeable health
                                            companion. Users can ask health-related
                                            questions, seek advice, and converse with
                                            DocGpt, our AI-powered health assistant. This
                                            not only increases access to health information
                                            but also enables users to make informed
                                            decisions about their health.
                                        </p>
                                        <p className="mt-4 text-gray-500 font-medium text-[14px]">
                                            Clysterum is developing AI-powered IoT devices
                                            to ensure real-time transmission of health
                                            data. This will facilitate accurate and timely
                                            information reaching emergency services without
                                            the need for manual data transfer in critical
                                            situations. The implementation of blockchain
                                            technology ensures secure storage of electronic
                                            health records, combating counterfeit drugs,
                                            and providing a decentralized structure for
                                            reliable data computation in modern healthcare.
                                            Clysterum Virtual Card allows users to securely
                                            store personal health information on the
                                            blockchain, granting them control over the
                                            sharing of health-related data. Additionally,
                                            Clysterum introduces CLY token as a payment
                                            method for healthcare expenses, adding features
                                            such as staking and various assistance systems
                                            for token holders.
                                        </p>
                                    </div>
                                    <div className="img-container w-full h-auto mt-6">
                                        <img
                                            src={Banner}
                                            alt="banner"
                                            className="w-full h-auto"
                                        />
                                    </div>
                                    <div
                                        className="third"
                                        id="third"
                                        style={{ scrollMarginTop: 100 }}
                                    >
                                        <h5 className="uppercase font-bold mt-8 text-lg">
                                            Artificial Intelligence in Healthcare:
                                        </h5>
                                        <p className="mt-4 text-gray-500 font-medium text-[14px]">
                                            Clysterum utilizes AI to automate healthcare
                                            processes, reduce errors, and enhance overall
                                            efficiency. Through AI-based technologies, the
                                            project aims to address various challenges in
                                            the healthcare sector, targeting cost savings,
                                            reduced hospitalizations, and improved
                                            diagnostic and treatment processes.
                                        </p>
                                    </div>
                                    <div
                                        className="fourth"
                                        id="fourth"
                                        style={{ scrollMarginTop: 100 }}
                                    >
                                        <h5 className="uppercase font-bold mt-8 text-lg">
                                            Tokenomics
                                        </h5>
                                        <p className="mt-4 text-gray-500 font-medium text-[14px]">
                                            Max. Supply: 3.000.000.000 (5 Bil.) Total
                                        </p>
                                        <p className="mt-1 text-gray-500 font-medium text-[14px]">
                                            Supply: 3.000.000.000 (3 Bil.)
                                        </p>
                                        <div className="list flex flex-col gap-1 mt-6">
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    R&D %20 - 600.000.000 (600 mln.)
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Liquidity %13 - 390.000.000 (390 mln.)
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Team %10 - 300.000.000 (300 mln.)
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Community %9 - 270.000.000 (270 mln.)
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Staking %8 - 240.000.00 (240 mln.)
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Pre-Seed %1 - 30.000.000 (30 mln.)
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Seed Sale %3 - 90.00.000 (90 mln.)
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Private Sale %5 - 150.000.000 (150 mln.)
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Public Sale %12 - 360.000.000 (360 mln.)
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Burn %6 - 180.000.000 (180 mln.)
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Aboiler %4 - 120.000.000 (120 mln.)
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Airdrop %6 - 180.000.000 (180 mln.)
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Advisor %3 - 90.000.000 (90 mln.)
                                                </p>
                                            </div>
                                        </div>
                                        <h5 className="uppercase font-semibold text-gray-700 mt-6">
                                            NOTE:
                                        </h5>
                                        <p className="mt-2 text-gray-500 font-medium text-[14px]">
                                            Aboiler: A feature within the Clysterum
                                            application that allows you to accumulate
                                            Clysterum tokens. Aboiler enables you to
                                            accumulate Clysterum tokens by taking specific
                                            steps within the application. These accumulated
                                            tokens are used exclusively within the
                                            application to access various advantages,
                                            rewards, or exclusive content. Aboiler is
                                            designed to encourage user interaction within
                                            the application and foster increased engagement
                                            within the community.
                                        </p>
                                    </div>
                                    <div
                                        className="fifth"
                                        id="fifth"
                                        style={{ scrollMarginTop: 100 }}
                                    >
                                        <h5 className="uppercase font-bold mt-8 text-lg">
                                            Vesting for TGE:
                                        </h5>
                                        <div className="list flex flex-col md:gap-1 gap-[6px] mt-6">
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    R&D: 12-month lock-up, four-month cliff over 48 months- 3 month vesting
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                Team: 12-month lock-up, 36 month 6 month vesting.
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                Public Sale: 20% TGE - 11.42% month linear vesting.
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                Private Sale: 10% TGE - 7.5% - four month cliff, month linear vesting
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Pre-Seed: 13.5% TGE - 7.20% 1 month
                                                    cliff, Month linear vesting
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Seed Sale: 17.5% TGE -8.25% 1 month
                                                    cliff, Month linear vesting
                                                </p>
                                            </div>
                                            {/* <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Pre-Sale: 15% TGE - 12.142% Month linear
                                                    vesting
                                                </p>
                                            </div> */}
                                            <div className="listitem flex gap-4 items-center">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                Airdrop: 9,09% month linear vesting.
                                                </p>
                                            </div>
                                            <div className="listitem flex gap-4 items-center mb-4">
                                                <div className="dot min-w-2 min-h-2 w-2 h-2 rounded-full bg-gray-500"></div>
                                                <p className="text-gray-500 font-medium text-[14px]">
                                                    Advisor: 25% - 12-month lock-up, 12-month
                                                    3 month vesting.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default SalesPart;
