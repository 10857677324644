import React from 'react'
import Container from '../common/Container'
import { BsCurrencyBitcoin } from "react-icons/bs";
import { FaCheck, FaDollarSign } from "react-icons/fa6";
import { SiAcclaim } from "react-icons/si";
import Encrypted from '../../images/icons/encrypted.png'
import Private from '../../images/icons/private.png'
import App from '../../images/icons/app.png'
import Secure from '../../images/icons/secure.png'
import DocGPT from '../../images/icons/docgpt.png'

function HowItWorks() {
      return (
            <div className='howitworks-container md:mt-20 -mt-6 md:mb-16 mb-0 relative'>
                  <div className="how-it-works-bg absolute left-0 md:top-8 top-[530px] w-full md:h-[700px] h-[700px] -z-40"></div>
                  <Container>
                        <div className="howitworks-inner-container flex flex-col gap-12">
                              <div className="howitworks-parts flex gap-7 md:flex-row flex-col md:px-0 px-6">
                                    <div className="part flex-1 w-full h-auto shadow-soft rounded-2xl flex flex-col gap-6 p-6 items-center bg-white">
                                          <div className="up flex gap-8 items-center justify-center w-full">
                                                <FaCheck className='h-12 w-auto text-blue1 flex-[1]' />
                                                <h5 className='text-xl font-bold flex-[7] uppercase'>Equitable Fundraising</h5>
                                          </div>
                                          <div className="bottom">
                                                <p>Join our unique CLY Token distribution methodology.</p>
                                          </div>
                                    </div>
                                    <div className="part flex-1 w-full h-auto shadow-soft rounded-2xl flex flex-col gap-6 p-6 items-center bg-white">
                                          <div className="up flex gap-8 items-center justify-center w-full">
                                                <FaCheck className='h-12 w-auto text-blue1 flex-[1]' />
                                                <h5 className='text-xl font-bold flex-[7] uppercase'>Precision-oriented</h5>
                                          </div>
                                          <div className="bottom">
                                                <p>Our care and sensitivity to people and health create strong beginnings.</p>
                                          </div>
                                    </div>
                                    <div className="part flex-1 w-full h-auto shadow-soft rounded-2xl flex flex-col gap-6 p-6 items-center bg-white">
                                          <div className="up flex gap-8 items-center justify-center w-full">
                                                <FaCheck className='h-12 w-auto text-blue1 flex-[1]' />
                                                <h5 className='text-xl font-bold flex-[7] uppercase'>CLY Platform</h5>
                                          </div>
                                          <div className="bottom">
                                                <p>Our promising healthcare project's dedicated platform supports progress in health technologies by focusing on CLY Token.</p>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div className="last-text-inner-container md:px-8 px-0 md:w-full w-11/12 flex justify-between h-full rounded-3xl md:py-8 py-12 mx-auto md:flex-row flex-col text-white md:gap-0 gap-12">
                              <div className="left flex-1 w-full flex-col">
                                    <h2 className='font-extrabold md:text-6xl text-5xl'>CLYSTERUM</h2>
                                    <h5 className='font-bold text-xl mt-6'>Powered by Clysterum</h5>
                                    <p className='mt-6'>Clysterum offers a decentralized health platform shaped by a promising and innovative project in the healthcare sector. Now is the time to invest in your health and future. Join the Clysterum ecosystem and become a part of this innovative transformation in the healthcare industry.</p>
                                    <p className='font-bold mt-4'>Clysterum, having achieved a significant milestone in building the future with AI-supported projects, presents the following advantages within its ecosystem:</p>
                              </div>
                              <div className="right flex-1 w-full justify-center gap-12 mt-16 md:flex hidden pl-6">
                                    <div className="left-icons flex flex-col gap-8">
                                          <div className="icon flex items-center gap-2">
                                                <div className="icon-container bg-white rounded-lg h-[70px] w-[70px] flex items-center justify-center">
                                                      <img src={DocGPT} alt="icon" className='w-[32px] h-[32px] invert' />
                                                </div>
                                                <h5 className='text-xl'>DocGPT</h5>
                                          </div>
                                          <div className="icon flex items-center gap-2">
                                                <div className="icon-container bg-white rounded-lg h-[70px] w-[70px] flex items-center justify-center">
                                                      <img src={App} alt="icon" className='w-[32px] h-[32px] invert' />
                                                </div>
                                                <h5 className='text-xl'>HealthAPP</h5>
                                          </div>
                                          <div className="icon flex items-center gap-2">
                                                <div className="icon-container bg-white rounded-lg h-[70px] w-[70px] flex items-center justify-center">
                                                      <img src={Secure} alt="icon" className='w-[32px] h-[32px] invert' />
                                                </div>
                                                <h5 className='text-xl'>Secure</h5>
                                          </div>
                                    </div>
                                    <div className="right-icons flex flex-col gap-8">
                                          <div className="icon flex items-center gap-2">
                                                <div className="icon-container bg-white rounded-lg h-[70px] w-[70px] flex items-center justify-center">
                                                      <img src={Private} alt="icon" className='w-[32px] h-[32px] invert' />
                                                </div>
                                                <h5 className='text-xl'>Private</h5>
                                          </div>
                                          <div className="icon flex items-center gap-2">
                                                <div className="icon-container bg-white rounded-lg h-[70px] w-[70px] flex items-center justify-center">
                                                      <img src={Encrypted} alt="icon" className='w-[32px] h-auto invert' />
                                                </div>
                                                <h5 className='text-xl'>Encrypted Information</h5>
                                          </div>
                                    </div>
                              </div>
                              <div className="mobile-right px-2 w-full flex-col gap-6 text-black md:hidden flex rounded-lg">
                                    <div className="rows w-full flex gap-4">
                                          <div className="icon flex w-full gap-4 items-center px-4 h-16 shadow-soft rounded-lg bg-white">
                                                <div className="img-icon w-[40px] h-[40px]  rounded-full flex items-center justify-center">
                                                      <img src={DocGPT} alt="icon" className='w-[40px] h-auto invert' />
                                                </div>
                                                <h5 className='text-xl'>DocGPT</h5>
                                          </div>
                                          <div className="icon flex w-full gap-4 items-center px-4 h-16 shadow-soft rounded-lg bg-white">
                                                <div className="img-icon w-[40px] h-[40px] rounded-full flex items-center justify-center">
                                                      <img src={App} alt="icon" className='w-[40px] h-auto invert' />
                                                </div>
                                                <h5 className='text-xl'>HealthAPP</h5>
                                          </div>
                                    </div>
                                    <div className="rows w-full flex gap-4">
                                          <div className="icon flex w-full gap-4 items-center px-4 h-16 shadow-soft rounded-lg bg-white">
                                                <div className="img-icon w-[40px] h-[40px] rounded-full flex items-center justify-center">
                                                      <img src={Secure} alt="icon" className='w-[40px] h-auto invert' />
                                                </div>
                                                <h5 className='text-xl'>Secure</h5>
                                          </div>
                                          <div className="icon flex w-full gap-4 items-center px-4 h-16 shadow-soft rounded-lg bg-white">
                                                <div className="img-icon w-[40px] h-[40px] rounded-full flex items-center justify-center">
                                                      <img src={Private} alt="icon" className='w-[40px] h-auto invert' />
                                                </div>
                                                <h5 className='text-xl'>Private</h5>
                                          </div>
                                    </div>

                                    <div className="icon flex w-full gap-4 items-center px-4 h-16 shadow-soft rounded-lg bg-white">
                                          <div className="img-icon w-[40px] h-[40px] rounded-full flex items-center justify-center">
                                                <img src={Encrypted} alt="icon" className='w-[40px] h-auto invert' />
                                          </div>
                                          <h5 className='text-xl'>Encrypted Information</h5>
                                    </div>
                              </div>
                        </div>
                  </Container>

            </div>
      )
}

export default HowItWorks
