import React from 'react'
import StakingMain from '../components/staking2/StakingMain'
import SalesPart from '../components/saleComponent/SalePart'

function Staking2() {
      return (
            <div>
                  <StakingMain />
                  <SalesPart />
            </div>
      )
}

export default Staking2
