import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import Container from "../components/common/Container";
import VerificationIcon from "../images/verificationicon.png";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

interface AlertProps {
   message: string;
}

const AlertComponent: React.FC<AlertProps> = ({ message }) => {
   return (
      <div
         style={{
            position: "fixed",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            paddingTop: "12px",
            paddingBottom: "12px",
            paddingLeft: "40px",
            paddingRight: "40px",
            backgroundColor: "#6640f4",
            color: "#fff",
            borderRadius: "5px",
            textAlign: "center",
            display: "inline-block",
            zIndex: "9999",
            fontWeight: 600,
         }}
      >
         {message}
      </div>
   );
};
const Verify = () => {
   const [alertVisible, setAlertVisible] = useState(false);
   const [alertMessage, setAlertMessage] = useState("");
   const { sendOtp, verifyOtp, user, isActive } = useAuth();
   const navigate = useNavigate();

   const showAlert = (message: string) => {
      setAlertMessage(message);
      setAlertVisible(true);

      setTimeout(() => {
         setAlertVisible(false);
      }, 3000);
   };
   const [dots, setDots] = useState(true);
   const [otp, setOtp] = useState("");

   useEffect(() => {
      if (isActive) {
         navigate('/profile');
      }
   }, [isActive, navigate]);

   const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      if (!otp) {
         showAlert("Please fill in the OTP field.");
         return;
      }

      try {
         await verifyOtp(otp.replace(/ /g, ''));
      } catch (error) {
         showAlert("Failed to verify OTP. Please try again.");
      }
   };

   const totalSecond = 180;

   const [countdown, setCountdown] = useState(totalSecond);
   const [countdownPercentage, setCountdownPercentage] = useState(1);

   useEffect(() => {
      const interval = setInterval(() => {
         if (countdown > 0) {
            setCountdown(countdown - 1);
            setCountdownPercentage(countdown / totalSecond);
         }
      }, 1000);

      return () => clearInterval(interval);
   }, [countdown]);

   const minutes = Math.floor(countdown / 60);
   const seconds = countdown % 60;
   const hideDots = () => {
      setDots(false);
   };
   const handleResend = () => {
      if (countdown > 0) {
         showAlert("You have to wait 3 minutes to resend a verification code!");
      } else {
         sendOtp(user?.email as string);
      }
   };

   return (
      <>
         {alertVisible && <AlertComponent message={alertMessage} />}

         <div className="verify-container">
            <Container>
               <div className="verify-inner-container max-w-[500px] w-11/12 mx-auto flex justify-center p-8 mb-10 rounded-3xl flex-col items-center text-clysblack">
                  <img
                     src={VerificationIcon}
                     alt="verification-icon"
                     className="w-24 h-24"
                  />
                  <h2 className="text-xl font-bold mt-2 mb-2">Verification</h2>
                  <form onSubmit={handleSubmit} className="mt-2 w-full">
                     <p className="mb-20 text-center text-clysblack text-sm">
                        A one-time password has been sent to the e-mail address
                        registered in the system.{" "}
                        <span className="font-semibold">
                           {" "}
                           Please check your e-mail address!
                        </span>
                     </p>

                     <div className="time-container w-full rounded-3xl relative flex bg-[#631FE9] justify-center items-center overflow-hidden">
                        {countdown != 0 ? (
                           <div
                              className={`border-inner-bar absolute w-full top-0 rounded-3xl h-full bg-blue1`}
                              style={{
                                 left: `${countdownPercentage * 100 - 100}%`,
                              }}
                           ></div>
                        ) : (
                           <></>
                        )}

                        <h5 className="text-white font-bold z-20 text-base">
                           {String(minutes).padStart(2, "0")}:
                           {String(seconds).padStart(2, "0")}
                        </h5>
                     </div>
                     <div className="otp-input-container w-full flex justify-center py-4 mb-2 relative">
                        <input
                           value={otp}
                           onChange={(e) => {
                              const sanitizedValue = e.target.value.replace(
                                 /\D/g,
                                 ""
                              );
                              let otpValue = sanitizedValue.slice(0, 3);

                              if (sanitizedValue.length > 3) {
                                 otpValue += " " + sanitizedValue.slice(3, 6);
                              }

                              setOtp(otpValue);
                           }}
                           className="w-full border outline-none py-3 rounded-lg text-center tracking-widest text-xl flex items-center justify-center font-bold"
                           onSelect={hideDots}
                           type="text"
                        />
                        {dots ? (
                           <div
                              className="dots flex absolute items-center justify-center top-9 gap-4 cursor-text"
                              onClick={hideDots}
                           >
                              <div className="dot w-3 h-3 rounded-full bg-gray-400"></div>
                              <div className="dot w-3 h-3 rounded-full bg-gray-400"></div>
                              <div className="dot w-3 h-3 rounded-full bg-gray-400"></div>
                              <div className="dot w-3 h-3 rounded-full bg-gray-400"></div>
                              <div className="dot w-3 h-3 rounded-full bg-gray-400"></div>
                              <div className="dot w-3 h-3 rounded-full bg-gray-400"></div>
                           </div>
                        ) : (
                           <></>
                        )}
                     </div>
                     {/* <input
                           type="text"
                           className="input input-bordered w-full max-w-xs"
                           placeholder="Enter OTP"
                           value={otp}
                           onChange={(e) => setOtp(e.target.value)}
                        /> */}
                     <div className="mt-2 w-full flex justify-center">
                        <button
                           type="submit"
                           className="bg-blue1 w-full py-3 text-white rounded-xl font-bold"
                        >
                           VERIFY
                        </button>
                     </div>
                     <div className="cant-verify w-full flex items-center justify-center text-clysblack mt-4">
                        <p>
                           Don't you get the verification code?{" "}
                           <span className="text-blue1 cursor-pointer">
                              <button type="button" onClick={handleResend}>
                                 Resend
                              </button>
                           </span>
                        </p>
                     </div>
                  </form>
               </div>
            </Container>
         </div>
      </>
   );
};

export default Verify;
