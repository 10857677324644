import React, { ReactNode } from 'react';

interface BiggerContainer {
      children: ReactNode;
}

function BiggerContainer({ children }: BiggerContainer) {
      return (
            <div className="container max-w-[1540px] h-full mx-auto">
                  {children}
            </div>
      );
}

export default BiggerContainer;