import React, { useEffect, useState } from 'react';
import ProfileMain from '../../components/profile/ProfileMain';
import { FaArrowRightLong, FaCheck } from 'react-icons/fa6';
import ProfileCommon from './ProfileCommon';
import { tokenAbi, tokenContract, claimContract, claimContract2 } from '../../const/ContractAddresses';
import { useAddress, useContract, useContractEvents, useContractRead, useWatchTransactions } from '@thirdweb-dev/react';
import { ethers } from 'ethers';
import { utils } from 'ethers';

interface TransactionRecord {
      date: any;
      amount: any;
      transactionName: string;
      fromAddress: string;
      toAddress: string;
}

function ProfileTransfer() {
      const [transfers, setTransfers] = useState<TransactionRecord[]>([]);
      const [currentPage, setCurrentPage] = useState(1);
      const transfersPerPage = 5;

      const toAddress = useAddress();
      const { contract: contract1 } = useContract(claimContract);
      const { data: data1, isLoading: isLoading1 } = useContractRead(contract1, "getUserTransactions", [toAddress])
      const { contract: contract2 } = useContract(claimContract2);
      const { data: data2, isLoading: isLoading2 } = useContractRead(contract2, "getUserTransactions", [toAddress])

      function shortenAddress(address: any, chars = 4) {
            return `${address.substring(0, chars + 2)}...${address.substring(address.length - chars)}`;
      }

      useEffect(() => {
            if (!isLoading1 && !isLoading2 && data1 && data2) {
                  const combinedData = [...data1, ...data2];

                  const formattedTransactions = combinedData.map((tx: TransactionRecord) => {
                        const amount = tx.amount ? utils.formatEther(tx.amount._hex) : '';
                        const date = tx.date ? new Date(tx.date * 1000).toLocaleString() : '';
                        const fromAddressShort = tx.fromAddress ? shortenAddress(tx.fromAddress) : '';
                        const toAddressShort = tx.toAddress ? shortenAddress(tx.toAddress) : '';

                        return {
                              date,
                              amount,
                              transactionName: tx.transactionName,
                              fromAddress: fromAddressShort,
                              toAddress: toAddressShort
                        };
                  });

                  setTransfers(formattedTransactions);
                  console.log(formattedTransactions);

            }
      }, [data1, data2, isLoading1, isLoading2]);

      const totalPages = Math.ceil(transfers.length / transfersPerPage);
      const currentTransfers = transfers.slice((currentPage - 1) * transfersPerPage, currentPage * transfersPerPage);

      const handlePageChange = (num: number) => {
            if (num > totalPages) {
                  setCurrentPage(totalPages);
            } else if (num < 1) {
                  setCurrentPage(1);
            } else {
                  setCurrentPage(num);
            }
      };

      const transactions = useWatchTransactions({
            address: toAddress,
            network: "binance-testnet",
      });

      useEffect(() => {
            try {
                  console.log(transactions);
            } catch (error) {
                  console.log(error)
            }
      }, [useWatchTransactions])

      return (
            <ProfileMain number={3}>
                  <div className="main-part w-full max-h-full h-full flex flex-col p-[16px] shadow-soft rounded-3xl items-center bg-white">
                        <div className="transfer-title flex mt-[22px] mb-[32px] w-full">
                              <h5 className='md:text-3xl text-xl font-black flex text-clysblack'>RECENT TRANSACTIONS</h5>
                        </div>
                        <div className="transfer-main-part-container flex w-full h-full flex-col">
                              <div className="table-container w-full flex md:pr-6 pr-2 flex-col gap-6">
                                    {currentTransfers.map((transfer, index) => (
                                          <div key={index} className='flex w-full border-b pb-4'>
                                                <div className="amount flex-[2]">
                                                      <h5>Amount</h5>
                                                      <p>{transfer.amount}</p>
                                                </div>
                                                <div className="address flex-[4]">
                                                      <h5>From</h5>
                                                      <p>{transfer.fromAddress}</p>
                                                </div>
                                                <div className="address flex-[4]">
                                                      <h5>To</h5>
                                                      <p>{transfer.toAddress}</p>
                                                </div>
                                                <div className="tx flex-[2]">
                                                      <h5>Transaction Name</h5>
                                                      <p>{transfer.transactionName}</p>
                                                </div>
                                          </div>
                                    ))}
                              </div>

                        </div>
                        <div className="pagination w-full justify-end flex gap-4 mt-[16px] mb-[10px] md:pr-[24px] pr-2">
                              <h5>Page</h5>
                              <div className='w-8 py-1 border-blue1 border rounded-md text-blue1 flex flex-row items-center justify-center md:text-xs cursor-pointer' onClick={() => handlePageChange(currentPage - 1)}>
                                    <FaArrowRightLong className='rotate-180' />
                              </div>
                              <h5 className='w-8 flex items-center justify-center'>{currentPage}</h5>
                              <div className='w-8 py-1 border-blue1 border rounded-md text-blue1 flex flex-row items-center justify-center md:text-xs cursor-pointer' onClick={() => handlePageChange(currentPage + 1)}>
                                    <FaArrowRightLong />
                              </div>
                        </div>
                  </div>
                  <ProfileCommon />
            </ProfileMain>
      )
}

export default ProfileTransfer
