import React, { useState } from 'react'
import ProfileMain from '../../components/profile/ProfileMain'

function ProfileAirdrop() {
      const [haveClaimable, setHaveClaimable] = useState(false);
      return (
            <ProfileMain number={1}>
                  <div className="main-part w-full max-h-full h-full flex flex-col gap-6">
                        <div className="profile-title flex">
                              <h5 className='text-3xl font-bold flex'>AIRDROP</h5>
                        </div>
                        <div className="profile-main-part-container flex w-full h-full flex-col gap-4">
                              <div className="up-part flex-1 w-full h-full flex gap-4 md:flex-row flex-col">
                                    <div className="left-part flex-[3] w-full h-full rounded-3xl bg-blue1 justify-center items-center flex text-white flex-col gap-4 md:text-xl text-base md:p-0 p-6">
                                          <div className="up flex gap-2 items-center">
                                                <p>You have claimed</p>
                                                <div className="number-container h-10 w-10 bg-blue6 rounded-full flex items-center justify-center">
                                                      <p className='font-bold text-yellow-300 text-2xl rounded-full'>2</p>
                                                </div>
                                                <p> airdrops.</p>
                                          </div>
                                          <div className="up flex gap-2 items-center">
                                                <p>You can claim</p>
                                                <div className="number-container h-10 w-10 bg-blue6 rounded-full flex items-center justify-center">
                                                      <p className='font-bold text-green-300 text-2xl rounded-full'>3</p>
                                                </div>
                                                <p> more airdrops.</p>
                                          </div>

                                    </div>
                                    <div className="right-part flex-[2] w-full h-full rounded-3xl bg-blue1 justify-center items-center flex flex-col gap-4 md:p-0 p-6">
                                          <div className="text text-white flex flex-col items-center justify-center text-xl">
                                                <h5>Total earned CLY</h5>
                                                <h5>from airdrops:</h5>
                                          </div>

                                          <h5 className='px-10 py-3 bg-blue6 text-green-300 font-bold text-2xl rounded-xl'>125.8</h5>
                                    </div>
                              </div>
                              <div className="claim-check flex-1 w-full h-full rounded-3xl flex items-center justify-center flex-col gap-6 md:py-0 md:px-0 py-16 px-6">
                                    {haveClaimable
                                          ?
                                          <>
                                                <p className='font-bold text-white md:text-2xl text-base'>You have claimable airdrop!</p>
                                                <button className='px-10 py-3 bg-blue6 text-white rounded-xl border-white border-2 text-base font-bold'>CLAIM YOUR AIRDROP</button>
                                          </>
                                          :
                                          <>
                                                <p className='font-bold text-white md:text-2xl text-base'>Your next claimable airdrop date:</p>
                                                <h5 className='px-16 py-3 bg-blue6 text-white rounded-xl border-white text-xl font-bold tracking-wider'>06.06.2024</h5>
                                          </>
                                    }

                              </div>
                        </div>
                  </div>
            </ProfileMain>
      )
}

export default ProfileAirdrop
