import React, { useState } from 'react'
import ProfileMain from '../../components/profile/ProfileMain'
import { BsCurrencyBitcoin } from "react-icons/bs";
import UserImage from '../../images/user-pic.jpg'
import { FaUserLarge } from "react-icons/fa6";
import CoinLogo from '../../images/coinlogo.png'
import ProfileCommon from './ProfileCommon';
import Image1 from '../../images/profile/profile-bottom/1.png'
import Image2 from '../../images/profile/profile-bottom/2.png'
import Image3 from '../../images/profile/profile-bottom/3.png'
import Image4 from '../../images/profile/profile-bottom/4.png'

function ProfileProfile() {
      const [haveProfilePic, setHaveProfilePic] = useState(true);
      return (
            <ProfileMain number={0}>
                  <div className="main-part w-full max-h-full h-full flex flex-col p-[20px] shadow-soft rounded-3xl items-center bg-white">
                        <h2 className='uppercase font-black text-black text-xl mb-[20px]'>Welcome to the account stats dashboard</h2>
                        <h3 className='text-[14px] mb-[20px] text-gray-600'>You can explore the stats for your connected wallet.</h3>
                        <div className="main-icons flex gap-4 md:flex-row flex-col">
                              <a href="/airdrop" className='flex-1 w-full flex flex-col gap-2 h-[193px]'>
                                    <div className="part px-[20px] py-[14px] flex flex-col flex-1 gap-2 rounded-xl shadow-soft items-center h-[193px]">
                                          <div className="img-contanier flex justify-center bg-white border-2 rounded-full h-[84px] w-[84px] items-center">
                                                <img src={Image1} alt="coinlogo" className='w-auto h-[64px]' />
                                          </div>
                                          <h3 className='uppercase font-black text-clysblack'>Airdrop Transfer</h3>
                                          <p className='text-sm text-gray-500 text-center'>You can view your airdrop withdrawal requests here.</p>
                                    </div>
                              </a>
                              <a href="/sales" className='flex-1 w-full flex flex-col gap-2 h-[193px]'>
                                    <div className="part px-[20px] py-[14px] flex flex-col flex-1 gap-2 rounded-xl shadow-soft items-center">
                                          <div className="img-contanier flex justify-center bg-white border-2 rounded-full h-[84px] w-[84px] items-center">
                                                <img src={Image2} alt="coinlogo" className='w-auto h-[64px]' />
                                          </div>
                                          <h3 className='uppercase font-black text-clysblack'>Community Withdrawal</h3>
                                          <p className='text-sm text-gray-500 text-center'>You can view your community sale withdrawal requests here.</p>
                                    </div>
                              </a>
                              <a href="#" className='flex-1 w-full flex flex-col gap-2 h-[193px]'>
                                    <div className="part px-[20px] py-[14px] flex flex-col flex-1 gap-2 rounded-xl shadow-soft items-center">
                                          <div className="img-contanier flex justify-center bg-white border-2 rounded-full h-[84px] w-[84px] items-center">
                                                <img src={Image3} alt="coinlogo" className='w-auto h-[64px]' />
                                          </div>
                                          <h3 className='uppercase font-black text-clysblack'>Staking</h3>
                                          <p className='text-sm text-gray-500 text-center'>Staking will be activated soon.</p>
                                    </div>
                              </a>
                              <a href="#" className='flex-1 w-full flex flex-col gap-2 h-[193px]'>
                                    <div className="part px-[20px] py-[14px] flex flex-col flex-1 gap-2 rounded-xl shadow-soft items-center">
                                          <div className="img-contanier flex justify-center bg-white border-2 rounded-full h-[84px] w-[84px] items-center">
                                                <img src={Image4} alt="coinlogo" className='w-auto h-[64px]' />
                                          </div>
                                          <h3 className='uppercase font-black text-clysblack'>CLY Points</h3>
                                          <p className='text-sm text-gray-500 text-center'>CP will be activated soon.</p>
                                    </div>
                              </a>

                        </div>
                  </div>
                  <ProfileCommon />
            </ProfileMain>
      )
}

export default ProfileProfile
